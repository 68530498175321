import { Loader } from "@/components/ProtectedRoute"
import { ContractForm } from "@/components/forms/contract.form"
import { Button } from "@/components/ui/button"
import { editContract, getContract, getUser } from "@/service/weexpertService"
import { format, isBefore } from "date-fns"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "@/components/ui/use-toast";
import { CONTRACT_STATE } from "@/constants"

const ContractEditForm = ({ contract }) => {

  const [client, setClient] = useState(contract?.data.contract.client);
  const [contractType, setContractType] = useState(contract?.data.contract.contractType);
  const [conclusionDate, setConclusionDate] = useState(new Date(contract?.data.contract.conclusionDate ?? null));
  const [startDate, setStartDate] = useState(new Date(contract?.data.contract.startDate ?? null));
  const [endDate, setEndDate] = useState(new Date(contract?.data.contract.endDate ?? null));
  const [denouementDate, setDenouementDate] = useState(contract?.data.contract.denouementDate ? new Date(contract?.data.contract.denouementDate) : undefined);
  const [grossAmount, setGrossAmount] = useState(+contract?.data.contract.grossAmount);
  const [taxDeductible, setTaxDeductible] = useState(contract?.data.contract.taxDeductible);
  const [pit, setPit] = useState(contract?.data.contract.pit);
  const [subjectMetter, setSubjectMetter] = useState(contract?.data.contract.subjectMatter);
  const [startText, setStartText] = useState(contract?.data.contract.startText);
  const [student, setStudent] = useState(contract?.data.contract.student);
  const [dontUseStartDate, setDontUseStartDate] = useState(contract?.data.contract.dontUseStartDate);
  const [youngDiscount, setYoungDiscount] = useState(contract?.data.contract.youngDiscount);
  const [contractState, setContractState] = useState(contract?.data.contract.state);
  const [signatory, setSignatory] = useState(contract?.data.contract.signatory);
  const [declarationLanguage, setDeclarationLanguage] = useState(contract?.data.contract.declarationLanguage);
  const [contractPayType, setContractPayType] = useState(contract?.data.contract.contractPayType);
  const [representativeCompany, setRepresentativeCompany] = useState(contract?.data.contract.representativeCompany);
  const [inputChecked, setInputChecked] = useState(false);

  const [isButtonBlocked, setIsButtonBlocked] = useState(false);
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
  const onSubmit = () => {
    setInputChecked(true)
    if (!client ||
      !contractType ||
      !conclusionDate ||
      !startDate ||
      !endDate ||
      (!grossAmount || (+grossAmount) <= 0) ||
      !taxDeductible ||
      !pit ||
      !subjectMetter ||
      isBefore(endDate, startDate) ||
      (!dontUseStartDate && (isBefore(startDate, conclusionDate))) ||
      !signatory ||
      !declarationLanguage ||
      !contractPayType ||
      (contractState == CONTRACT_STATE.Ended && !denouementDate) ||
      (dontUseStartDate && !startText )
    ) {
      return;
    }
    setIsButtonBlocked(true)
    editContract(
      contract.data.contract.id,
      client.id,
      contractState,
      contractType,
      format(conclusionDate, 'yyyy-MM-dd'),
      dontUseStartDate ? format(conclusionDate, 'yyyy-MM-dd') : format(startDate, 'yyyy-MM-dd'),
      format(endDate, 'yyyy-MM-dd'),
      +grossAmount,
      pit,
      subjectMetter,
      taxDeductible,
      student,
      signatory,
      youngDiscount,
      declarationLanguage,
      contractPayType,
      denouementDate ? format(denouementDate, 'yyyy-MM-dd') : null,
      dontUseStartDate,
      startText,
      representativeCompany
    ).then((response) => {
      if (response.status === 200) {
        toast({
          title: t("alerts:toast_succes_invoice_edit_description"),
          description: t("alerts:edit_succes"),
        })
        queryClient.invalidateQueries()
        navigate('/contracts');
      }
    })
      .catch((error) => {
        console.log(error)
        toast({
          title: t("alerts:toast_error_invoice_title"),
          description: "alerts:toast_error_invoice_description",
          variant: "destructive"
        })
      }).finally(() => {
        setIsButtonBlocked(false)
      })

  }
  if (isUserLoading) {
    return <Loader/>
  }
  if(user.data.roles === 'CLIENT') {
    navigate('/404')
  }
  return <div className="relative bg-grayLightMainBg px-8">
    <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
      <div className="flex justify-between py-8 items-center ">
        <div className=" w-5/12  text-4xl ">
          {t('contracts:contract_edit')}
        </div>
        <div className='w-6/12 flex justify-end'>
          <Button disabled={isButtonBlocked} className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
            onSubmit()
          }}>
            {t('button_save')}
          </Button>
        </div>
      </div>
    </div>
    <div className='w-full py-8'>
      <div className=" text-2xl ">
        {contract.data.contract.contractId}
      </div>
      <ContractForm
        inputChecked={inputChecked}
        client={client}
        setClient={setClient}
        contractType={contractType}
        setContractType={setContractType}
        conclusionDate={conclusionDate}
        setConclusionDate={setConclusionDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        grossAmount={grossAmount}
        setGrossAmount={setGrossAmount}
        taxDeductible={taxDeductible}
        setTaxDeductible={setTaxDeductible}
        pit={pit}
        setPit={setPit}
        subjectMetter={subjectMetter}
        setSubjectMetter={setSubjectMetter}
        student={student}
        setStudent={setStudent}
        contractState={contractState}
        setContractState={setContractState}
        paidAmount={contract.data.contract.bills.reduce((accumulator, current) => accumulator + (+current.grossAmount), 0)}
        signatory={signatory}
        setSignatory={setSignatory}
        setYoungDiscount={setYoungDiscount}
        youngDiscount={youngDiscount}
        declarationLanguage={declarationLanguage}
        setDeclarationLanguage={setDeclarationLanguage}
        contractPayType={contractPayType}
        setContractPayType={setContractPayType}
        denouementDate={denouementDate}
        setDenouementDate={setDenouementDate}
        dontUseStartDate={dontUseStartDate}
        setDontUseStartDate={setDontUseStartDate}
        startText={startText}
        setStartText={setStartText}
        representativeCompany={representativeCompany}
        setRepresentativeCompany={setRepresentativeCompany}
      />
    </div>
  </div>
}
export const ContractEditPage = () => {
  const { id } = useParams()
  const { data: contract, isLoading } = useQuery({
    queryKey: ['contract', id], queryFn: () => {
      return getContract(id)
    }
  })

  if (isLoading) {
    return <Loader />
  }
  return <ContractEditForm contract={contract} />
}
