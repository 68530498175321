import * as React from "react";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { getUser, newContractor } from "../service/weexpertService";
import { useNavigate } from "react-router-dom";
import { ContractorsForm, CONTRACTORS_FORM_ACTION_CREATE } from "../components/contractors/ContractorsForm";
import { validateNoneCyrillic } from "../constants";
import { useTranslation } from "react-i18next";
import { toast } from "@/components/ui/use-toast";
import { useQuery } from "react-query";

const CounterpartyCreatePage = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputChecked, setInputChecked] = useState(false);
  const [counterpartyType, setCounterpartyType] = useState();
  const [counterpartyOrigin, setCounterpartyOrigin] = useState();
  const [counterpartyName, setCounterpartyName] = useState('');
  const [counterpartyAdress, setCounterpartyAdress] = useState('');
  const [counterpartyCity, setCounterpartyCity] = useState("");
  const [counterpartyEmail, setCounterpartyEmail] = useState('');
  const [counterpartyPostIndex, setCounterpartyPostIndex] = useState('');
  const [counterpartyCountry, setCounterpartyCountery] = useState();
  const [counterpartyNip, setCounterpartyNip] = useState('');

  const { data: authData } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false, refetchOnWindowFocus: false });

  const onClickSendButtonHandler = () => {
    setInputChecked(true)
    if (
      ((!counterpartyName) || counterpartyName.includes(',')) || (!validateNoneCyrillic(counterpartyName)) ||
      (!counterpartyAdress && !validateNoneCyrillic(counterpartyAdress)) ||
      (!counterpartyCity && !validateNoneCyrillic(counterpartyCity)) ||
      (counterpartyPostIndex && !validateNoneCyrillic(counterpartyPostIndex)) ||
      !counterpartyType ||
      !counterpartyOrigin
    ) {
      return
    }

    newContractor(
      counterpartyType.value,
      [],
      counterpartyName,
      counterpartyAdress,
      counterpartyPostIndex,
      counterpartyCity,
      counterpartyEmail,
      counterpartyCountry.content.value,
      counterpartyNip,
      counterpartyOrigin.value
    )
      .then((response) => {
        if (response.status === 200) {
          const role = authData.data.roles;
          toast({
            title: t("alerts:toast_succes_counterparty_create_title"),
            description: t("alerts:toast_succes_counterparty_create_description"),
          })
          navigate(role === 'CLIENT'
            ?  '/admin/contractors'
            : `/admin/contractors/details/${response.data.id}`);
        }
      })
      .catch((error) => {
        toast({
          title: t("alerts:toast_error_counterparty_title"),
          description: "alerts:toast_error_counterparty_description",
          variant: "destructive"
        })
      });
  };

  return (
    <div className="w-full px-8 py-10 bg-grayLightMainBg">
      <div className="text-4xl ">{t("counterparties:new_contractor")}</div>
      <div className=" w-9/12 pt-4">
        <ContractorsForm
          action={CONTRACTORS_FORM_ACTION_CREATE}
          inputChecked={inputChecked}
          companyType={counterpartyType}
          setCompanyType={setCounterpartyType}
          NIP={counterpartyNip}
          setNIP={setCounterpartyNip}
          companyName={counterpartyName}
          setCompanyName={setCounterpartyName}
          companyOrigin={counterpartyOrigin}
          setCompanyOrigin={setCounterpartyOrigin}
          companyCountry={counterpartyCountry}
          setCompanyCountry={setCounterpartyCountery}
          postIndex={counterpartyPostIndex}
          setPostIndex={setCounterpartyPostIndex}
          mail={counterpartyEmail}
          setMail={setCounterpartyEmail}
          city={counterpartyCity}
          setCity={setCounterpartyCity}
          address={counterpartyAdress}
          setAddress={setCounterpartyAdress}
        />
        <div className="pt-5 flex justify-end">
          <Button className="w-36" onClick={() => {
            onClickSendButtonHandler();
          }}>
            {t("button_add")}
          </Button>
        </div>
      </div>
    </div>
  )

}
export default CounterpartyCreatePage;
