export function LanguageProficiency({ languages }) {

  const proficiencyLabels = {
    BEGINNER: "Beginner",
    INTERMEDIATE: "Intermediate",
    ADVANCED: "Advanced",
    FLUENT: "Fluent",
    NATIVE: "Native",
  };

  const proficiencyColors = {
    BEGINNER: "bg-blue-50 text-blue-600", 
    INTERMEDIATE: "bg-teal-50 text-teal-600", 
    ADVANCED: "bg-yellow-50 text-yellow-600",
    FLUENT: "bg-orange-50 text-orange-600",
    NATIVE: "bg-red-50 text-red-600",
  };

  return (
    <div className="w-2/12 py-4">
      <div className="space-y-1">
        {languages.map((lang) => (
          <div key={lang.code} className="flex items-center justify-between text-md">
            <span className="text-xs capitalize">{lang.code.toLowerCase()}</span>
            <span
              className={`inline-block rounded-full px-3 py-1 text-xs font-medium ${proficiencyColors[lang.proficiency]}`}
            >
              {proficiencyLabels[lang.proficiency] || lang.proficiency}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}