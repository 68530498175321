import { AlertCircle } from "lucide-react";
import * as React from "react"

import { cn } from "../../lib/utils"
import { Alert, AlertTitle } from "./alert";
import { Input } from "./input";
import { Label } from "./label";
import { v4 as uuidv4 } from 'uuid';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

const SelectWithAlert = React.forwardRef(({ inputId = uuidv4(),
  inputClassName = 'h-12 my-2 h-12 mt-2 mb-4 bg-white', error = false, errorMessage = null, type, label, value, onChangeValue, options, placeholder, disabled = false, ...props }, ref) => {
  return (
    <>
      <Label htmlFor="inputId" className="pb-1">{label}</Label>
      <Select value={value}
        onValueChange={onChangeValue}
        disabled={disabled}
      >
        <SelectTrigger className={inputClassName}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options &&
            options.map((option) => (
              <SelectItem key={option.label} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>

      {error && (<div className="mt-4">
        <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
          <AlertCircle className="h-4 w-4 !static !mr-2" />
          <AlertTitle className="m-0 !p-0">
            {errorMessage}
          </AlertTitle>
        </Alert>
      </div>)
      }
    </>
  );
})

SelectWithAlert.displayName = "Input"
export { SelectWithAlert }
