import { INVOICE_TYPES } from "@/constants";
import axios from "axios";
import Cookies from "js-cookie";
import fileDownload from "js-file-download";
import { vatMap } from "../components/admin-page-components/admin-create-components/invoice-create-components/InvoiceItem";
import { format } from "date-fns";

const serverUrl = import.meta.env.VITE_BACKEND;

axios.interceptors.request.use(function (config) {
  if (config.data instanceof FormData) {
    delete config.headers["Content-Type"];
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  if (
    config.url && (
    config.url.endsWith("/auth/login") ||
    config.url.endsWith("/auth/password-reset") ||
    config.url.startsWith("https://amaki-work.s3.amazonaws.com/") ||
    config.url.startsWith("https://s3")
    )
  ) {
    return config;
  }
  const token = Cookies.get("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export function login(username, password) {
  return axios.post(`${serverUrl}/auth/login`, {
    login: username,
    password: password,
  });
}

export function getRateToPLN(currencyCode, date) {
  return axios.get(`${serverUrl}/currencies/${currencyCode}/${date}`);
}

export function changePasword(password, newPassword) {
  return axios.put(`${serverUrl}/auth/change-password`, {
    password: newPassword,
  });
}

export function updateUser(firstname, lastname, phone, email) {
  return axios.put(`${serverUrl}/user/me`, {
    firstName: firstname,
    lastName: lastname,
    email: email,
    phone: phone,
  });
}

export function getUser() {
  return axios.get(`${serverUrl}/user/me`);
}

export function restorePasswordRequest(email) {
  return axios.post(`${serverUrl}/auth/password-reset`, {
    email: email,
  });
}

export function bulkRestorePassword(clientsIds) {
  return axios.post(`${serverUrl}/clients/bulk-password-restore`, {
    clientsIds,
  });
}
export function bulkBillsStateApprove(ids) {
  return axios.put(`${serverUrl}/bills/bulk-update-state`, {
    ids,
    state: "Accepted",
  });
}

export function restorePasswordComplete(password, hash) {
  return axios.post(`${serverUrl}/auth/password-reset/${hash}`, {
    password: password,
  });
}

export function getUsers(take, skip) {
  return axios.get(`${serverUrl}/users/${take}/${skip}`);
}

export function newUser(firstName, lastName, phone, email, roles) {
  return axios.post(`${serverUrl}/users/`, {
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    email: email,
    isActive: true,
    roles: roles,
  });
}

export function createContract(
  clientId,
  state,
  contractType,
  conclusionDate,
  startDate,
  endDate,
  grossAmount,
  pit,
  subjectMatter,
  taxDeductible,
  student,
  signatory,
  youngDiscount,
  declarationLanguage,
  contractPayType,
  denouementDate,
  dontUseStartDate,
  startText,
  representativeCompany,
) {
  return axios.post(`${serverUrl}/contracts/`, {
    clientId,
    state,
    contractType,
    conclusionDate,
    startDate,
    endDate,
    grossAmount,
    pit,
    subjectMatter,
    taxDeductible,
    student,
    signatory,
    youngDiscount,
    declarationLanguage,
    contractPayType,
    denouementDate,
    dontUseStartDate,
    startText,
    representativeCompany,
  });
}
export function createAnnex(
  contractId,
  state,
  changes,
  acceptedDate,
  signatory,
  representativeCompany,
) {
  return axios.post(`${serverUrl}/contract/${contractId}/annex`, {
    state,
    changes,
    acceptedDate,
    signatory,
    representativeCompany,
  });
}
export function editAnnex(
  contractId,
  annexId,
  state,
  changes,
  acceptedDate,
  signatory,
  representativeCompany,
) {
  return axios.put(`${serverUrl}/contract/${contractId}/annex/${annexId}`, {
    state,
    changes,
    acceptedDate,
    signatory,
    representativeCompany,
  });
}
export function approveAnnex(
  contractId,
  annexId,
  changes,
  acceptedDate,
  signatory,
) {
  return axios.put(`${serverUrl}/contract/${contractId}/annex/${annexId}`, {
    state: "Accepted",
    changes,
    acceptedDate,
    signatory,
  });
}

export function editContract(
  contractId,
  clientId,
  state,
  contractType,
  conclusionDate,
  startDate,
  endDate,
  grossAmount,
  pit,
  subjectMatter,
  taxDeductible,
  student,
  signatory,
  youngDiscount,
  declarationLanguage,
  contractPayType,
  denouementDate,
  dontUseStartDate,
  startText,
  representativeCompany,
) {
  return axios.put(`${serverUrl}/contracts/${contractId}`, {
    clientId,
    state,
    contractType,
    conclusionDate,
    startDate,
    endDate,
    grossAmount,
    pit,
    subjectMatter,
    taxDeductible,
    student,
    signatory,
    youngDiscount,
    declarationLanguage,
    contractPayType,
    denouementDate,
    dontUseStartDate,
    startText,
    representativeCompany,
  });
}
export function getContract(id) {
  return axios.get(`${serverUrl}/contracts/${id}`);
}

export function getBill(id) {
  return axios.get(`${serverUrl}/bill/${id}`);
}
export function getBillPDF(id, billId) {
  return axios
    .get(`${serverUrl}/bill/${id}/pdf`)
    .then((res) => {
      return axios.get(res.data.pdf, {
        responseType: "blob",
      });
    })
    .then((data) => {
      fileDownload(data.data, `${billId}.pdf`);
    });
}
export function getContractPdf(id, contractId) {
  return axios
    .get(`${serverUrl}/contract/${id}/pdf`)
    .then((res) => {
      return axios.get(res.data.pdf, {
        responseType: "blob",
      });
    })
    .then((data) => {
      fileDownload(data.data, `${contractId}.pdf`);
    });
}

export function sendAnnexToHR(id) {
  return axios.get(`${serverUrl}/contract/annex/${id}/send-pdf`);
}

export function sendContractToHR(id) {
  return axios.get(`${serverUrl}/contract/${id}/send-pdf`);
}
export function getContractEndPdf(id, contractId) {
  return axios
    .get(`${serverUrl}/contract/${id}/end-pdf`)
    .then((res) => {
      return axios.get(res.data.pdf, {
        responseType: "blob",
      });
    })
    .then((data) => {
      fileDownload(data.data, `ROZWIĄZNIE_UMOWY_${contractId}.pdf`);
    });
}

export function getAnnexPDF(id, contractId) {
  return axios
    .get(`${serverUrl}/contract/annex/${id}/pdf`)
    .then((res) => {
      return axios.get(res.data.pdf, {
        responseType: "blob",
      });
    })
    .then((data) => {
      fileDownload(data.data, `${contractId}-annex.pdf`);
    });
}

export function getContracts(take, skip, search = null, filters = []) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search.trim())}` : "")
      .filter((e) => e)
      .join("&"),
  );

  return axios.get(`${serverUrl}/contracts/${take}/${skip}?${qs}`);
}

export function getBills(take, skip, search = null, filters = []) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search.trim())}` : "")
      .filter((e) => e)
      .join("&"),
  );
  return axios.get(`${serverUrl}/bills/${take}/${skip}?${qs}`);
}

export function createBill(
  contractId,
  billState,
  billType,
  taxDeductible,
  pit,
  conclusionDate,
  grossAmount,
  emerytalneRentowe,
  chorobowe,
  zdrowotne,
  bankAccount,
  acceptedDate,
  signatory,
  comment,
  specificationId = null,
  currency,
  currencyDate,
) {
  return axios.post(`${serverUrl}/bill/`, {
    contractId,
    billState,
    billType,
    taxDeductible,
    pit,
    conclusionDate,
    grossAmount,
    emerytalneRentowe,
    chorobowe,
    zdrowotne,
    bankAccount,
    acceptedDate,
    specificationId,
    signatory,
    comment,
    currency,
    currencyDate,
  });
}

export function editBill(
  id,
  contractId,
  billState,
  billType,
  taxDeductible,
  pit,
  conclusionDate,
  grossAmount,
  emerytalneRentowe,
  chorobowe,
  zdrowotne,
  bankAccount,
  acceptedDate,
  signatory,
  comment,
  specificationId = null,
  billId = null,
  currency = null,
  currencyDate = null,
) {
  return axios.put(`${serverUrl}/bill/${id}`, {
    contractId,
    billState,
    billType,
    taxDeductible,
    pit,
    conclusionDate,
    grossAmount,
    emerytalneRentowe,
    chorobowe,
    zdrowotne,
    bankAccount,
    acceptedDate,
    specificationId,
    signatory,
    comment,
    billId,
    currency,
    currencyDate,
  });
}

export function changeUser(firstName, lastName, phone, email, roles, id) {
  return axios.put(`${serverUrl}/users/${id}`, {
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    email: email,
    isActive: true,
    roles: roles,
  });
}

export function blockUser(
  firstName,
  lastName,
  phone,
  email,
  roles,
  id,
  status,
) {
  return axios.put(`${serverUrl}/users/${id}`, {
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    email: email,
    isActive: status,
    roles: roles,
  });
}

export function deleteUser(id) {
  return axios.delete(`${serverUrl}/users/${id}`);
}

export function newClient(
  firstName,
  lastName,
  email,
  phone,
  pesel,
  bankAccountIncubator,
  bankAccountClient,
  state,
  city,
  postIndex,
  passport,
  street,
  house,
  flat,
  dateOfBirth,
  citizenship,
  representativeCompany,
) {
  return axios.post(`${serverUrl}/clients/`, {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    pesel: pesel !== "" ? pesel : null,
    bankAccountsIncubator: bankAccountIncubator,
    bankAccountsClient: bankAccountClient,
    state: state,
    city: city,
    postIndex: postIndex,
    passport: passport,
    street: street,
    house: house,
    flat: flat !== "" ? flat : null,
    isActive: true,
    dateOfBirth: dateOfBirth,
    citinzenship: citizenship,
    representativeCompany: representativeCompany,
  });
}

export function getClients(take, skip, search = null, filters = []) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search.trim())}` : "")
      .filter((e) => e)
      .join("&"),
  );

  return axios.get(`${serverUrl}/clients/${take}/${skip}?${qs}`);
}

export function getUserById(id) {
  return axios.get(`${serverUrl}/users/${id}`);
}

export function getClientById(id) {
  return axios.get(`${serverUrl}/clients/${id}`);
}
export function getClientLinkRestore(id) {
  return axios.get(`${serverUrl}/client/${id}/get-restore-link`);
}
export function getContractorById(id) {
  return axios.get(`${serverUrl}/counterparty/${id}`);
}

export function connectUserToClinet(clientId, userId) {
  return axios.post(`${serverUrl}/users/client`, {
    userId: userId,
    clientId: clientId,
  });
}

export function createSharedClientPool(userId, name, clientIds) {
  return axios.post(`${serverUrl}/users/shared-client-pool`, {
    userId,
    clientIds,
    name,
  });
}

export function getSharedClientPool(poolId) {
  return axios.get(`${serverUrl}/users/shared-client-pool/${poolId}`);
}

export function deleteSharedClientPool(poolId) {
  return axios.delete(`${serverUrl}/users/shared-client-pool/${poolId}`);
}
export function getSharedClientPools() {
  return axios.get(`${serverUrl}/users/shared-client-pool/`);
}

export function assignClientToContractors(contractorId, clientId) {
  return axios.post(`${serverUrl}/counterparties/${contractorId}/client`, {
    clientId: clientId,
  });
}

export function unassignClientFromContractors(contractorId, clientId) {
  return axios.put(`${serverUrl}/counterparties/${contractorId}/client`, {
    // FUCK AXIOS
    clientId: clientId,
  });
}

export function unassignClient(clientId) {
  return axios.delete(`${serverUrl}/users/client/${clientId}`);
}
export function changeClient(
  firstName,
  lastName,
  email,
  phone,
  pesel,
  bankAccountIncubator,
  bankAccountClient,
  state,
  city,
  postIndex,
  passport,
  street,
  house,
  status,
  flat,
  dateOfBirth,
  citizenship,
  id,
  representativeCompany,
) {
  return axios.put(`${serverUrl}/clients/${id}`, {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    pesel: pesel !== "" ? pesel : null,
    bankAccountsIncubator: bankAccountIncubator,
    bankAccountsClient: bankAccountClient,
    state: state,
    city: city,
    postIndex: postIndex,
    passport: passport,
    street: street,
    house: house,
    isActive: status,
    flat: flat,
    dateOfBirth: dateOfBirth,
    citinzenship: citizenship,
    representativeCompany: representativeCompany,
  });
}

export function deleteClient(id) {
  return axios.delete(`${serverUrl}/clients/${id}`);
}

export function blockClient(
  firstName,
  lastName,
  email,
  phone,
  pesel,
  bankAccountIncubator,
  bankAccountClient,
  state,
  city,
  postIndex,
  passport,
  street,
  house,
  id,
  status,
) {
  return axios.put(`${serverUrl}/clients/${id}`, {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    pesel: pesel,
    bankAccountIncubator: bankAccountIncubator,
    bankAccountClient: bankAccountClient,
    state: state,
    city: city,
    postIndex: postIndex,
    passport: passport,
    street: street,
    house: house,
    isActive: status,
  });
}

export function getOneClient(id) {
  return axios.get(`${serverUrl}/clients/${id}`);
}

export function updateFrilancerProfile(
  skills,
  frameworks,
  toolsAndTechnologies,
  programingLanguage,
  educationLevel,
  fieldOfStudy,
  languages,
  interestedInProjects,
  rate,
  avialibility,
  currentJobTitle,
  yearOfProffesionalExpierence,
  linkedin,
) {
  return axios.put(`${serverUrl}/profile/update`, {
    skills: skills,
    frameworks: frameworks,
    toolsAndTechnologies: toolsAndTechnologies,
    programingLanguage: programingLanguage,
    educationLevel: educationLevel,
    fieldOfStudy: fieldOfStudy,
    languages: languages,
    interestedInProjects: interestedInProjects,
    rate: rate,
    linkedin: linkedin,
    avialibility: avialibility,
    currentJobTitle: currentJobTitle,
    yearOfProffesionalExpierence: yearOfProffesionalExpierence,
  });
}

export function getFreelancersProfiles(
  take,
  skip,
  search = null,
  filters = [],
) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search)}` : "")
      .filter((e) => e)
      .join("&"),
  );

  return axios.get(`${serverUrl}/profiles/${take}/${skip}?${qs}`);
}

export function getCandidates(take, skip, search = null, filters = []) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search)}` : "")
      .filter((e) => e)
      .join("&"),
  );

  return axios.get(`${serverUrl}/new-profiles/${take}/${skip}?${qs}`);
}

export function getOneFreelancer(id) {
  return axios.get(`${serverUrl}/profiles/${id}`);
}

export function getNewOneFreelancer(id) {
  return axios.get(`${serverUrl}/new-profiles/${id}`);
}

export function getCVForClient(id) {
  return axios.get(`${serverUrl}/new-profile/${id}/download-cv`).then((res) => {
    return res;
  });
}
export function saveClinetProfileNote(profileId, note) {
  return axios.post(`${serverUrl}/profile/note`, {
    profileId,
    note,
  });
}

export function surveyStep2(
  currentJobTitle,
  yearOfProffesionalExpierence,
  linkedin,
) {
  return axios.put(`${serverUrl}/profile/stage/1`, {
    currentJobTitle: currentJobTitle,
    yearOfProffesionalExpierence: yearOfProffesionalExpierence,
    linkedin: linkedin,
  });
}
export function touchExpirienceStage(jobTitles, knowledges, experienceDate) {
  console.log(experienceDate);
  return axios.put(`${serverUrl}/new-profile/stage/1`, {
    jobTitles,
    knowledges,
    startDate: format(experienceDate, "yyyy-MM-dd"),
  });
}

export function touchConditionStage(hourlyRate, monthlyRate, languages) {
  return axios.put(`${serverUrl}/new-profile/stage/2`, {
    hourlyRate,
    monthlyRate,
    languages,
  });
}

export function touchConcentStage(
  concent,
  interestedInProject,
  availability,
  marketing,
) {
  return axios.put(`${serverUrl}/new-profile/stage/4`, {
    concent,
    interested: interestedInProject,
    availability,
    marketing,
  });
}

export function surveyStep3(
  skills,
  frameworks,
  toolsAndTechnologies,
  programingLanguage,
) {
  return axios.put(`${serverUrl}/profile/stage/2`, {
    skills: skills,
    frameworks: frameworks,
    toolsAndTechnologies: toolsAndTechnologies,
    programingLanguages: programingLanguage,
  });
}

export function surveyStep4(educationLevel, fieldOfStudy, languages) {
  return axios.put(`${serverUrl}/profile/stage/3`, {
    educationLevel: educationLevel,
    fieldOfStudy: fieldOfStudy,
    languages: languages,
  });
}
export function surveyStep5(concent, rate, avialibility, interestedInProject) {
  return axios.put(`${serverUrl}/profile/stage/4`, {
    concent: concent,
    rate: rate,
    avialibility: avialibility,
    interestedInProject: interestedInProject,
  });
}

export function createInvoice(
  invoiceType,
  domainDate,
  state,
  counterparty,
  counterpartyType,
  counterpartyOrigin,
  counterpartyName,
  counterpartyPostIndex,
  counterpartyNIP,
  counterpartyCity,
  counterpartyEmail,
  counterpartyCountry,
  currentAdress,
  typeOfPayment,
  wayOfPayment,
  paymentStatus,
  paymentDueDate,
  paymentCurrency,
  paymentCurrencyRate,
  paymentIban,
  invoiceTotalNetto,
  invoiceBrutto,
  invoiceVAT,
  clientId,
  invoiceItems,
  internalComment,
  externalComment,
  paymentCurrencyRateDate,
  createdAt,
  useDifferentRecipient,
  recipientCounterparty,
) {
  return axios.post(`${serverUrl}/invoices/`, {
    invoiceType: invoiceType,
    domainDate: domainDate,
    state: state,
    counterparty: counterparty,
    counterpartyType: counterpartyType,
    counterpartyOrigin: counterpartyOrigin,
    counterpartyName: counterpartyName,
    counterpartyPostIndex: counterpartyPostIndex,
    counterpartyNIP: counterpartyNIP,
    counterpartyCity: counterpartyCity,
    counterpartyEmail: counterpartyEmail,
    counterpartyCountry: counterpartyCountry,
    counterpartyAddress: currentAdress,
    typeOfPayment: typeOfPayment,
    wayOfPayment: wayOfPayment,
    paymentStatus: paymentStatus,
    paymentDueDate: paymentDueDate,
    paymentCurrency: paymentCurrency,
    paymentCurrencyRate: paymentCurrencyRate,
    paymentIban: paymentIban,
    invoiceTotalNetto: +invoiceTotalNetto,
    invoiceBrutto: +invoiceBrutto,
    invoiceVAT: +invoiceVAT,
    clientId: clientId,
    createdAt,
    paymentCurrencyRateDate,
    invoiceItems: invoiceItems.map((e) => {
      return {
        name: e.name,
        GTU: e.GTU,
        pkwiu: e.pkwiu,
        priceNetto: +e.unitPriceNetto,
        priceBrutto:
          +e.unitPriceNetto + +e.unitPriceNetto * (vatMap[e.vatRate] / 100),
        unit: e.unit,
        amount: e.quantity,
        typeOfVat: e.vatRate,
        vat: +e.unitPriceNetto * (vatMap[e.vatRate] / 100),
      };
    }),
    internalComment: internalComment,
    externalComment: externalComment,
    useDifferentRecipient,
    recipientCounterparty,
  });
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function editInvoice(
  id,
  invoiceType,
  domainDate,
  state,
  counterparty,
  counterpartyType,
  counterpartyOrigin,
  counterpartyName,
  counterpartyPostIndex,
  counterpartyNIP,
  counterpartyCity,
  counterpartyEmail,
  counterpartyCountry,
  currentAdress,
  typeOfPayment,
  wayOfPayment,
  paymentStatus,
  paymentDueDate,
  paymentCurrency,
  paymentCurrencyRate,
  paymentIban,
  invoiceTotalNetto,
  invoiceBrutto,
  invoiceVAT,
  clientId,
  invoiceItems,
  internalComment,
  externalComment,
  paymentCurrencyRateDate,
  createdAt,
  useDifferentRecipient,
  recipientCounterparty,
  newInvoiceId,
) {
  return axios.put(`${serverUrl}/invoice/${id}`, {
    invoiceType: invoiceType,
    domainDate: domainDate,
    state: state,
    counterparty: counterparty,
    counterpartyType: counterpartyType,
    counterpartyOrigin: counterpartyOrigin,
    counterpartyName: counterpartyName,
    counterpartyPostIndex: counterpartyPostIndex,
    counterpartyNIP: counterpartyNIP,
    counterpartyCity: counterpartyCity,
    counterpartyEmail: counterpartyEmail,
    counterpartyCountry: counterpartyCountry,
    counterpartyAddress: currentAdress,
    typeOfPayment: typeOfPayment,
    wayOfPayment: wayOfPayment,
    paymentStatus: paymentStatus,
    paymentDueDate: paymentDueDate,
    paymentCurrency: paymentCurrency,
    paymentCurrencyRate: paymentCurrencyRate,
    paymentIban: paymentIban,
    invoiceTotalNetto: +invoiceTotalNetto,
    invoiceBrutto: +invoiceBrutto,
    invoiceVAT: +invoiceVAT,
    clientId: clientId,
    paymentCurrencyRateDate,
    createdAt: createdAt,
    newInvoiceId,
    invoiceItems: invoiceItems.map((e) => {
      return {
        name: e.name,
        GTU: e.GTU,
        pkwiu: e.pkwiu,
        priceNetto: +e.unitPriceNetto,
        priceBrutto:
          +e.unitPriceNetto + +e.unitPriceNetto * (vatMap[e.vatRate] / 100),
        unit: e.unit,
        amount: e.quantity,
        typeOfVat: e.vatRate,
        vat: +e.unitPriceNetto * (vatMap[e.vatRate] / 100),
      };
    }),
    internalComment: internalComment,
    externalComment: externalComment,
    useDifferentRecipient,
    recipientCounterparty,
  });
}

export function getInvoices(take, skip, search = null, filters = []) {
  const qs = filters
    .filter((e) => e.value && e.value.filter((e) => e).length != 0)
    .map((e) => {
      return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
    })
    .concat(search ? `search=${encodeURIComponent(search.trim())}` : "")
    .join("&");
  return axios.get(`${serverUrl}/invoices/${take}/${skip}?${qs}`);
}

export function getInvoiceById(id) {
  return axios.get(`${serverUrl}/invoice/${id}`);
}

export function getInvoicePdfById(id) {
  return axios
    .get(`${serverUrl}/invoice/${id}/pdf`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `invoice-${id}.pdf`);
    });
}

export function getVatReportForClient(id) {
  return axios
    .get(`${serverUrl}/report/vat/${id}`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `client-vat-report-${id}.xlsx`);
    });
}

export function getPlatformEvidenceReport(bankReportId) {
  return axios
    .get(`${serverUrl}/bank-report/${bankReportId}/platform-report`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `platforms-evidence-${bankReportId}.xlsx`);
    });
}

export function getBankReportXLSX(id) {
  return axios
    .get(`${serverUrl}/bank-report/${id}/raport`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `bank-report-${id}.xlsx`);
    });
}
export function getBankReportHealth(id) {
  return axios
    .get(`${serverUrl}/report/bank-report/${id}`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `bank-report-health-${id}.xlsx`);
    });
}

export function getCandidatesExport() {
  return axios
    .get(`${serverUrl}/new-profile/export`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `candidates.xlsx`);
    });
}
export function getClientIncomeReport() {
  return axios
    .get(`${serverUrl}/report/clients-income/`, {
      responseType: "blob",
    })
    .then((res) => {
      return fileDownload(res.data, `client-income.xlsx`);
    });
}

export function newContractor(
  counterpartyType,
  clientIds,
  name,
  address,
  postIndex,
  city,
  email,
  country,
  NIP,
  origin,
) {
  return axios.post(`${serverUrl}/counterparties/`, {
    counterpartyType: counterpartyType,
    clientIds: clientIds,
    name: name,
    address: address,
    postIndex: postIndex,
    city: city,
    email: email,
    country: country,
    NIP: NIP,
    origin: origin,
  });
}

export function getContractors(take, skip, search = null, filters = []) {
  const qs = filters
    .filter(
      (e) =>
        e.value &&
        e.value.filter((e) => e != undefined && e != null && e !== "").length !=
          0,
    )
    .map((e) => {
      return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
    })
    .join("&");
  const qsearth = search ? `&search=${encodeURIComponent(search.trim())}` : "";
  return axios.get(
    `${serverUrl}/counterparties/${take}/${skip}?${qs}${qsearth}`,
  );
}

export function changeContractor(
  id,
  counterpartyType,
  name,
  address,
  postIndex,
  city,
  email,
  country,
  NIP,
  origin,
  active = true,
) {
  return axios.put(`${serverUrl}/counterparties/${id}`, {
    id: id,
    counterpartyType: counterpartyType,
    clientIds: [], // backend is retarded a bit
    name: name,
    address: address,
    postIndex: postIndex,
    city: city,
    email: email,
    country: country,
    NIP: NIP,
    origin: origin,
    active: active,
  });
}
////////inherit from invoice

export function inheritFromInvoice(id, invoiceType, issueDate) {
  return axios.post(`${serverUrl}/invoices/createFrom/${id}`, {
    invoiceType: invoiceType,
    issueDate,
  });
}

export function cloneInvoice(id, issueDate, invoiceType) {
  return axios.post(`${serverUrl}/invoices/clone/${id}`, {
    issueDate,
    invoiceType,
  });
}

export function getSelectedInvoicesPdf(
  selectedInvoices,
  lang,
  secondLang = null,
) {
  const invoicesIdsString = selectedInvoices.join(",");
  const secondLangAsQueryParam = secondLang ? `&secondLang=${secondLang}` : "";
  return axios
    .get(
      `${serverUrl}/invoices/pdf?invoiceIds=${invoicesIdsString}&lang=${lang}${secondLangAsQueryParam}`,
      {
        responseType: "blob",
      },
    )
    .then((res) => {
      fileDownload(res.data, `invoices.pdf`);
    });
}

export function getSelectedInvoicePdf(
  selectedInvoice,
  lang,
  secondLang = null,
  invoice_type = "proforma",
  invoiceDocumentNumber,
) {
  const secondLangAsQueryParam = secondLang ? `&secondLang=${secondLang}` : "";
  return axios
    .get(
      `${serverUrl}/invoices/pdf?invoiceIds=${selectedInvoice}&lang=${lang}${secondLangAsQueryParam}`,
      {
        responseType: "blob",
      },
    )
    .then((res) => {
      fileDownload(
        res.data,
        `${invoice_type === INVOICE_TYPES.FAKTURA_KONCOWA ? "FINAL_INVOICE" : invoice_type}_${invoiceDocumentNumber}.pdf`,
      );
    });
}

export function getSelectedInvoicesExel(selectedInvoices) {
  const invoicesIdsString = selectedInvoices.join(",");
  return axios
    .get(`${serverUrl}/report/invoices/?invoiceIds=${invoicesIdsString}`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `invoices_report.xlsx`);
    });
}

export function getBillExport(selected) {
  const billIds = selected.join(",");
  return axios
    .get(`${serverUrl}/report/bills?billIds=${billIds}`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `bills_report.xlsx`);
    });
}

export function connectInvoice(fromInvoice, toInvoice) {
  return axios.post(`${serverUrl}/invoices/connectInvoices`, {
    fromInvoice: +fromInvoice,
    toInvoice: +toInvoice,
  });
}

export function getReportVat(clientId, month, year) {
  return axios
    .get(`${serverUrl}/report/vat/${clientId}?month=${month}&year=${year}`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `client-vat-report.xlsx`);
    });
}

export function getCounterpartyExcel() {
  return axios
    .get(`${serverUrl}/report/counterparties`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `counterparties.xlsx`);
    });
}
export function getClientsExcel() {
  return axios
    .get(`${serverUrl}/report/clients`, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, `counterparties.xlsx`);
    });
}

export function getSpecificaitons(take, skip, search) {
  const qs = "".concat(
    search ? `search=${encodeURIComponent(search.trim())}` : "",
  );
  return axios.get(`${serverUrl}/specifications/${take}/${skip}?${qs}`);
}

export function getSpecificaiton(specificationId) {
  return axios.get(`${serverUrl}/specification/${specificationId}`);
}

export function getSpecificaitonPDF(specificationId) {
  return axios
    .get(`${serverUrl}/specification/${specificationId}/pdf`)
    .then((res) => {
      return axios.get(res.data.pdf, {
        responseType: "blob",
      });
    })
    .then((data) => {
      fileDownload(data.data, `specification-${specificationId}.pdf`);
    });
}

export function getReports(take, skip) {
  return axios.get(`${serverUrl}/bank-reports/${take}/${skip}`);
}

export function createReport(title, fromDate, endDate, representativeCompany) {
  return axios.post(`${serverUrl}/bank-reports`, {
    title,
    fromDate,
    endDate,
    representativeCompany,
  });
}


export function editBankReport(bankReportId,title, fromDate, endDate, representativeCompany, state) {
  return axios.put(`${serverUrl}/bank-report/${bankReportId}`, {
    title,
    fromDate,
    endDate,
    representativeCompany,
    state
  });
}

export function getReport(id) {
  return axios.get(`${serverUrl}/bank-report/${id}`);
}
export function performBankReportCheck(id) {
  return axios.get(`${serverUrl}/bank-report/${id}/check`);
}
export function connectInvoiceToTransaction(transactionId, invoiceId) {
  return axios.post(
    `${serverUrl}/bank-report/transaction/${transactionId}/connect-invoice`,
    {
      invoiceId,
    },
  );
}

export function createPlatformExlanation(
  transactionId,
  counterparty,
  platformType,
  invoice,
  costInvoice,
) {
  return axios.post(
    `${serverUrl}/bank-report/transaction/${transactionId}/platform-explanation`,
    {
      counterparty,
      platformType,
      invoice,
      costInvoice,
    },
  );
}

export function createPlatformExlanationReportBased(
  bankReportId,
  counterparty,
  platformType,
  invoice,
  costInvoice,
  bankAccount,
) {
  return axios.post(
    `${serverUrl}/bank-report/${bankReportId}/platform-explanation`,
    {
      counterparty,
      platformType,
      invoice,
      costInvoice,
      bankAccount,
    },
  );
}

export function editPlatformExplanation(
  platformExplanationId,
  counterparty,
  platformType,
  invoice,
  costInvoice,
  bankAccount
) {
  return axios.put(
    `${serverUrl}/bank-report/platform-explanation/${platformExplanationId}`,
    {
      counterparty,
      platformType,
      invoice,
      costInvoice,
      bankAccount
    },
  );
}

export function disconnectInvoiceFromTransaction(transactionId) {
  return axios.delete(
    `${serverUrl}/bank-report/transaction/${transactionId}/disconnect-invoice`,
  );
}

export function bankUpload(id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`${serverUrl}/bank-report/${id}/import`, formData);
}

export function touchArtifactSatge(file, links, cvLink, noCv) {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }
  formData.append(
    "data",
    JSON.stringify({
      platformLinks: links,
      cvLink,
      noCv,
    }),
  );
  return axios.put(`${serverUrl}/new-profile/stage/3`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadArifactForClient(clientId, file, artifactType) {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }
  formData.append(
    "data",
    JSON.stringify({
      artifactType: artifactType,
    }),
  );
  return axios.post(`${serverUrl}/client/${clientId}/artifacts`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getArtifactsForClient(clientId) {
    return axios.get(`${serverUrl}/client/${clientId}/artifacts`);
}

export function downloadArtifact(artifactId) {
  return axios.get(`${serverUrl}/artifact/${artifactId}/download`)
    .then((res) => {
      window.open(res.data.presigned);
      return;
    })
}

export function deleteArtifact(artifactId) {
  return axios.delete(`${serverUrl}/artifact/${artifactId}`);
}

export function touchProfile(
  jobTitles,
  knowledges,
  experienceDate,
  hourlyRate,
  monthlyRate,
  languages,
  file,
  links,
  cvLink,
  noCv,
  interestedInProject,
  availability,
  marketing,
) {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }
  formData.append(
    "data",
    JSON.stringify({
      jobTitles,
      knowledges,
      startDate: format(experienceDate, "yyyy-MM-dd"),
      hourlyRate,
      monthlyRate,
      languages,
      platformLinks: links,
      cvLink,
      noCv,
      interested: interestedInProject,
      availability,
      marketing,
    }),
  );
  return axios.put(`${serverUrl}/new-profile/update`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getTransactionsForReport(
  reportId,
  take,
  skip,
  search = null,
  filters = [],
) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search.trim())}` : "")
      .filter((e) => e)
      .join("&"),
  );

  return axios.get(
    `${serverUrl}/bank-report/${reportId}/transactions/${take}/${skip}?${qs}`,
  );
}
/**
 * @param type all|matchet|unmatched
 */
export function getInvoicesForReport(
  reportId,
  take,
  skip,
  search = null,
  filters = [],
  type,
) {
  const qs = new URLSearchParams(
    filters
      .filter(
        (e) =>
          e &&
          e.value &&
          e.value.filter((e) => e !== undefined && e !== null && e !== "")
            .length != 0,
      )
      .map((e) => {
        return `${e.field}=${e.value.map((e) => encodeURIComponent(e)).join(",")}`;
      })
      .concat(search ? `search=${encodeURIComponent(search.trim())}` : "")
      .filter((e) => e)
      .join("&"),
  );
  return axios.get(
    `${serverUrl}/bank-report/${reportId}/invoices/${take}/${skip}/${type}?${qs}`,
  );
}

export function getTransaction(transactionId) {
  return axios.get(`${serverUrl}/bank-report/transaction/${transactionId}`);
}

export function bankUploadExplanation(
  transactionId,
  title,
  counterparty,
  date,
  account,
  currency,
  vat,
  vatAmount,
  grossAmount,
  netAmount,
  transactionType,
) {
  return axios.post(
    `${serverUrl}/bank-report/transaction/${transactionId}/explanation`,
    {
      title,
      counterparty,
      date,
      account,
      currency,
      vat,
      vatAmount,
      grossAmount,
      netAmount,
      transactionType,
    },
  );
}

export function editBankTransactionExplanation(
  explanationId,
  title,
  counterparty,
  date,
  account,
  currency,
  vat,
  vatAmount,
  grossAmount,
  netAmount,
  transactionType,
) {
  return axios.put(`${serverUrl}/bank-report/explanation/${explanationId}`, {
    title,
    counterparty,
    date,
    account,
    currency,
    vat,
    vatAmount,
    grossAmount,
    netAmount,
    transactionType,
  });
}

export function deleteBankTransactionExplanation(explanationId) {
  return axios.delete(`${serverUrl}/bank-report/explanation/${explanationId}`);
}

export function deletePlatformExplanation(explanationId) {
  return axios.delete(
    `${serverUrl}/bank-report/platform-explanation/${explanationId}`,
  );
}

export function batchExplanationCreate(
  transactionsIds,
  vat,
  transactionType,
  title,
) {
  return axios.post(`${serverUrl}/bank-report/batch-explanations`, {
    transactionsIds,
    vat,
    transactionType,
    title,
  });
}
export function batchMarkMatched(ids) {
  return axios.post(`${serverUrl}/bank-report/transactions/batch-matched`, {
    ids,
  });
}

/**
 * @typedef {stage} json
 * @property {string} text
 * @property {number} grossAmount
 * @property {null} endDate
 * @property {number} order
 * @typedef {object} json
 * @property {number} contract
 * @property {string} description
 * @property {string} functionality
 * @property {stage[]} stages
 * @property {string} stages.text
 * @property {number} stages.grossAmount
 * @property {null} stages.endDate
 * @property {number} stages.order
 */
export function createSpecifiation({
  contract,
  description,
  functionality,
  signatory,
  stages,
}) {
  return axios.post(`${serverUrl}/specifications`, {
    contract,
    description,
    functionality,
    signatory,
    stages,
  });
}

/**
 * @typedef {stage} json
 * @property {string} text
 * @property {number} grossAmount
 * @property {null} endDate
 * @property {number} order
 * @typedef {object} json
 * @property {number} contract
 * @property {string} description
 * @property {string} functionality
 * @property {stage[]} stages
 * @property {string} stages.text
 * @property {number} stages.grossAmount
 * @property {null} stages.endDate
 * @property {number} stages.order
 */
export function editSpecification({
  specificationId,
  contract,
  description,
  functionality,
  signatory,
  stages,
}) {
  return axios.put(`${serverUrl}/specification/${specificationId}`, {
    contract,
    description,
    functionality,
    signatory,
    stages,
  });
}
