import * as React from "react"
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable
} from "@tanstack/react-table"

import { Checkbox } from "@/components/ui/checkbox"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table"

import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { BaseDialog } from "./dialog";
import { PAGINATION_SETTINGS } from "@/constants";
import { getUsers, getOneClient, connectUserToClinet, unassignClient } from "@/service/weexpertService";
import { DataTablePagination } from "../ui/table-pagination"
import { formatDateString } from "@/styles"

export default function ClientAssignUsersPopup(props) {

    const { handleRefreshData } = props;
    const [open, setOpen] = useState(false)
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [realData, setRealData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_SETTINGS.invoices);
    const [refreshData, setRefreshData] = useState(false);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: rowsPerPage });
    const [clientData, setClientData] = useState('');

    useEffect(() => {
        getUsers(rowsPerPage, page * rowsPerPage)
            .then(res => {
                setRealData(res.data);
            });
        getOneClient(props.id)
            .then(data => {
                setClientData(data.data);
            });
    }, [props, rowsPerPage, page]);

    const handleClick = (event, userId) => {
        const connect = () => {
            return connectUserToClinet(clientData.id, userId)
                .then(res => {
                    setClientData(res.data);
                    setRefreshData(prev => !prev);
                    handleRefreshData();
                    setOpen(false);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        console.error(error);
                    } else {
                        console.error(error);
                    }
                });
        }
        const remove = () => {
            unassignClient(userId)
                .then(res => {
                    setRefreshData(prev => !prev);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        console.error(error);
                    } else {
                        console.error(error);
                    }
                });
        }
        if (selected === userId) {
            remove();
            setSelected(null);
        } else {
            connect()
            setSelected(userId);
        }
    };

    useEffect(() => {
        setPage(pagination.pageIndex)
        setRowsPerPage(pagination.pageSize)
    }, [pagination])

    const columns = [
        {
            id: "select",
            cell: ({ row }) => {
                const isItemSelected = clientData.user && clientData.user.id === row.original.id ? true : false;
                return <Checkbox className='flex flex-row items-center justify-center py-0'
                    onClick={(e) => { handleClick(e, row.original.id) }}
                    checked={isItemSelected}
                    aria-label="Select row"
                />
            },
            enableSorting: false,
            enableHiding: false
        },
        {
            accessorKey: "name",
            header: t("name"),
            cell: ({ row }) => (
                <div>
                    {row.original.firstName + ' ' + row.original.lastName}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "email",
            header: "Email",
            cell: ({ row }) => (
                <div>
                    {row.original.email}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "phone",
            header: t("phone"),
            cell: ({ row }) => (
                <div>
                    {row.original.phone}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "status",
            header: t("common:status"),
            cell: ({ row }) => (
                <div>
                    {row.original.isActive ? "true" : "false"}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "status",
            header: t("created"),
            cell: ({ row }) => (
                <div>
                    {formatDateString(row.original.createdAt)}
                </div>
            ),
            key: uuidv4()
        },

    ]
console.log(realData)
    const table = useReactTable({
        data: realData.users ?? [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        manualPagination: true,
        rowCount: realData?.total ?? 0,
        state: {
            pagination
        },
        initialState: {
            pagination
        }
    });

    return <BaseDialog
        dialogTitle={`${t('clients:button_assingn_assistant')} :`}
        buttonText={t('clients:button_assingn_assistant')}
        open={open}
        setOpen={setOpen}
    >
        <div className="w-[1000px] bg-grayLightMainBg z-12">
            <div className="w-full">
             
                
                        <div className="text-base"> {` ${clientData.firstName} ${clientData.lastName}`}</div>
                    
                
                <div className="rounded-md border p-2 h-[400px] box-border overflow-y-scroll mt-6 mb-10">
                    {realData && (
                        <Table>
                            <TableHeader>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <TableRow key={uuidv4()}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <TableHead className="pt-3 pb-3"
                                                    key={uuidv4()}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </TableHead>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map(row => (
                                        <TableRow
                                            key={row.id}
                                            data-state={row.getIsSelected() && "selected"}
                                        >
                                            {row.getVisibleCells().map(cell => (
                                                <TableCell className="relative text-sm pt-3 pb-3"
                                                    key={uuidv4()}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            className="h-24 text-center"
                                        >
                                            {t("common:no_results")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                </div>
            </div>
            <div className="w-full bg-black relative ">
                <div className="fixed h-[80px] left-0 bottom-[0px] z-10 w-full bg-grayLightMainBg p-10 flex justify-start items-center shadow-sm">
                    <DataTablePagination table={table} />
                </div>
            </div>
        </div>
    </BaseDialog>
}
