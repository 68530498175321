import * as React from "react";
import { useState, useEffect } from "react";
import { MainInformation } from "../../components/account-components/main.information";
import { Button } from "@/components/ui/button";
import { v4 } from "uuid";
import {
  availability,
  OPTIONS_LANGUAGE,
  OPTIONS_LANGUAGE_LEVEL,
} from "@/constants";
import {
  getCVForClient,
  getUser,
  touchProfile,
} from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { toast } from "@/components/ui/use-toast";
import { useQuery } from "react-query";
import { AlertCircle, InfoIcon, Loader, TrashIcon } from "lucide-react";
import {
  JOB_TITLE_OPTIONS,
  TOOLS_SKILLS,
  MONTHLY_RATE,
} from "../../profile.constants";
import MultipleSelector from "@/components/ui/multiple-selector";
import { DatePicker } from "@/components/ui/date-picker";
import { Combobox } from "@/components/ui/combobox";
import { Alert } from "@/components/ui/alert";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { AlertTitle } from "@mui/material";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LanguageAbility } from "./profile.step_two.page";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { Checkbox } from "@/components/ui/checkbox";

export function ProfileClientSide() {
  const { t } = useTranslation();
  const [languagesData, setLanguagesData] = useState([]);
  const { data: userData, isLoading: loading } = useQuery({
    queryKey: ["userMeProfile"],
    queryFn: getUser,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const profile = data.data.profile;
      setMonthlyRate({ value: profile?.monthlyRate });
      setHourlyRate(profile.hourlyRate);
      setMarketing(profile.marketing);
      setJob(profile.jobTitles.map((e) => ({ value: e, label: e })));
      setTools(profile.knowleges.map((e) => ({ value: e, label: e })));
      setExperienceDate(new Date(profile?.experienceDate));
      const clientsSavedLanguages = profile.languages.map((e) => {
        const a = {
          id: v4(),
          language: {
            value:
              OPTIONS_LANGUAGE().filter((p) => p.id == e.code)[0]?.id ?? v4(),
            label: e.name,
          },
          proficiency:
            OPTIONS_LANGUAGE_LEVEL()
              .filter((p) => p.id == e.proficiency)
              .map((s) => {
                return {
                  value: s.id,
                  label: s.value,
                };
              })[0] ?? null,
        };
        return a;
      });
      setLanguagesData(
        clientsSavedLanguages.length != 0
          ? clientsSavedLanguages
          : [{ id: 1, language: null, proficiency: null }],
      );

      if (profile.cvLink && false === profile.cvLink.startsWith("s3")) {
        setCvLink(profile.cvLink);
      }
      setMaxHours(+profile.availability);
      setInterestedInProject(profile.interestedInProjects);
      for (const profileLink of profile.links) {
        if (profileLink.platform === "LinkedIn") {
          setLinkedin(profileLink.link);
        }

        if (profileLink.platform === "Behance") {
          setBehance(profileLink.link);
        }

        if (profileLink.platform === "Telegram") {
          setTelegram(profileLink.link);
        }

        if (profileLink.platform === "WhatsApp") {
          setWhatsApp(profileLink.link);
        }
      }
    },
  });

  const [freelancerProfile, setFrilancerProfile] = useState(true);
  const [edit, setEdit] = useState(false);
  const id = userData?.data?.id;
  const [jobTitles, setJob] = useState([]);
  const [tools, setTools] = useState([]);
  const [experienceDate, setExperienceDate] = useState("");
  const [inputChecked, setInputChecked] = useState(false);
  const [monthlyRate, setMonthlyRate] = useState();
  const [hourlyRate, setHourlyRate] = useState(0);

  const [useFileCV, setUseFileCv] = useState(true);
  const [dontHaveCv, setDontHaveCV] = useState(false);
  const [cvLink, setCvLink] = useState("");
  const [file, setFile] = useState();
  const [linkedin, setLinkedin] = useState("");
  const [behance, setBehance] = useState("");
  const [telegram, setTelegram] = useState("");
  const [whatsApp, setWhatsApp] = useState("");

  const [maxHours, setMaxHours] = useState();
  const [interestedInProject, setInterestedInProject] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const onLanguageUpdate = (id, language, proficiency) => {
    if (
      languagesData.some(
        (l) =>
          l.language && l.language?.value === language?.value && id != l.id,
      )
    ) {
      toast({
        title: t("survey:error_duplicate_language"),
        variant: "destructive",
      });
    }
    setLanguagesData((prevState) => {
      if (!prevState || !Array.isArray(prevState)) {
        return [];
      }
      return prevState.map((e) => {
        if (e.id == id) {
          return {
            id: id,
            language,
            proficiency,
          };
        }
        return e;
      });
    });
  };

  const onLanguageDelete = (id) => {
    setLanguagesData((prevState) => {
      return prevState.filter((e) => e.id != id);
    });
  };

  const checkErrorLanguage = () => {
    return (
      inputChecked &&
      languagesData.some((item) => !item.language && !item.proficiency)
    );
  };

  const languageItem = languagesData.map((item) => (
    <div key={item.id}>
      <LanguageAbility
        languageEntry={item}
        onLanguageChange={onLanguageUpdate}
        onLanguageDelete={onLanguageDelete}
        disabled={!edit}
      />
    </div>
  ));

  const addNextLanguageKnowleges = () => {
    setLanguagesData((prev) =>
      prev.concat({
        id: v4(),
        language: null,
        proficiency: null,
      }),
    );
  };

  const handleHourlyRateChange = (e) => {
    const value = Math.max(1, Math.min(300, e.target.value));
    setHourlyRate(value);
  };

  useEffect(() => {
    if (!freelancerProfile) {
      setEdit(false);
    }
  }, [freelancerProfile]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {id ? (
        <div className="w-full text-4xl pl-8 pt-8">
          {`${userData && userData.data.firstName} ${userData && userData.data.lastName}`}
        </div>
      ) : (
        <div className="w-72 text-4xl">{t("users:account_title")}</div>
      )}
      <Tabs
        defaultValue="profile"
        className="w-full md:w-12/12 lg:w-12/12 xl:w-8/12 pt-10 px-8"
      >
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="account">
            {t("users:main_information")}
          </TabsTrigger>
          <TabsTrigger value="profile">
            {" "}
            {t("users:freelancer_profile")}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <div className="pb-4 pt-4">
            <div className="w-9/12 p-3 bg-blueCyanBg text-blueText rounded-lg flex items-center ">
              {<InfoIcon className="h-5 w-5 mr-2" />}{" "}
              {t("users:main_profile_message")}
            </div>
          </div>
          <MainInformation userData={userData.data} />
        </TabsContent>
        <TabsContent value="profile">
          <h3> {t("users:freelancer_profile")}</h3>
          <div className="py-4">
            <MultipleSelector
              options={JOB_TITLE_OPTIONS}
              maxSelected={3}
              label={t("survey:label_job_title")}
              errorMessage={t("empty_data_invalid")}
              error={inputChecked && (!jobTitles || jobTitles.length == 0)}
              value={jobTitles}
              onChange={(e) => {
                console.log(e);
                setJob(e);
              }}
              placeholder={t("survey:label_job_title")}
              inputClassName={"h-12 my-2 bg-white"}
              disabled={!edit}
            />
            <MultipleSelector
              options={TOOLS_SKILLS}
              label={t("survey:placeholder_tools")}
              placeholder={t("survey:placeholder_tools")}
              errorMessage={t("empty_data_invalid")}
              error={inputChecked && (!tools || tools.length == 0)}
              value={tools}
              onChange={(e) => {
                setTools(e);
              }}
              inputClassName={"h-12 my-2 bg-white"}
              disabled={!edit}
            />
            <DatePicker
              label={t("survey:label_experience")}
              errorMessage={t("empty_data_invalid")}
              value={experienceDate}
              date={experienceDate ? new Date(experienceDate) : null}
              defaultMonth={
                experienceDate ? new Date(experienceDate) : new Date()
              }
              setDate={setExperienceDate}
              error={inputChecked && !experienceDate}
              placeholder={t("survey:label_experience")}
              inputClassName={"h-10 my-2 bg-white"}
              disabled={!edit}
            />
            <Combobox
              className={"my-2"}
              key={monthlyRate?.value}
              label={"Monthly rate (USD)"}
              placeholder={"Monthly rate (USD)"}
              creatable
              value={monthlyRate}
              disabled={!edit}
              error={inputChecked && !monthlyRate}
              errorMessage={t("empty_data_invalid")}
              options={MONTHLY_RATE}
              onChange={(newValue) => {
                setMonthlyRate(newValue);
              }}
            />
            <InputWithAlert
              type="number"
              label={"Hourly rate (USD)"}
              placeholder={"Enter hourly rate"}
              value={hourlyRate}
              disabled={!edit}
              onChange={handleHourlyRateChange}
              error={inputChecked && !hourlyRate}
              errorMessage={t("empty_data_invalid")}
            />
            <div className="knowlege-of-language-wrapper pt-4">
              {languageItem}
            </div>
            {checkErrorLanguage() && (
              <div className="mt-1">
                <Alert
                  variant="destructive"
                  className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]"
                >
                  <AlertCircle className="h-4 w-4 !static !mr-2" />
                  <AlertTitle className="m-0 !p-0">
                    {t("empty_data_invalid")}
                  </AlertTitle>
                </Alert>
              </div>
            )}
            {edit && (
              <Button
                onClick={() => {
                  addNextLanguageKnowleges(languagesData);
                }}
              >
                +
              </Button>
            )}
          </div>

          <div className="w-1/3 mb-4 sm:w-[100%]">
            <div className="relative mt-4 w-4/4">
              <InputWithAlert
                value={linkedin}
                disabled={!edit}
                onChange={(e) => {
                  setLinkedin(e.target.value);
                }}
                label={"LinkedIn"}
                placeholder={"LinkedIn"}
              />
            </div>
            <div className="relative mt-4 w-4/4">
              <InputWithAlert
                disabled={!edit}
                value={behance}
                onChange={(e) => {
                  setBehance(e.target.value);
                }}
                label={"Behance"}
                placeholder={"Behance"}
              />
            </div>
            <div className="relative mt-4 w-4/4">
              <InputWithAlert
                disabled={!edit}
                value={telegram}
                onChange={(e) => {
                  setTelegram(e.target.value);
                }}
                label={"Telegram"}
                placeholder={"Telegram"}
              />
            </div>
            <div className="relative mt-4 w-4/4">
              <InputWithAlert
                disabled={!edit}
                value={whatsApp}
                onChange={(e) => {
                  setWhatsApp(e.target.value);
                }}
                label={"WhatsApp"}
                placeholder={"WhatsApp"}
              />
            </div>
          </div>

          <div className="mt-4" />

          <div className="w-4/4 ">
            <SelectWithAlert
              inputClassName={"h-12 w-[100%] mt-2 mb-4 bg-white"}
              label={t("survey:label_availability")}
              placeholder={t("survey:placeholder_availability")}
              value={maxHours}
              disabled={!edit}
              onChangeValue={(e) => setMaxHours(e)}
              options={availability.map((e) => ({ label: e, value: e }))}
            />
          </div>

          <div className="w-2/5 ">
            <div className="mr-8 flex justify-between pt-4">
              <div>I am interested to get job offers</div>
              <Switch
                onCheckedChange={(e) => {
                  setInterestedInProject(e);
                }}
                checked={interestedInProject}
              />
            </div>
          </div>

          <div className="w-full flex  justify-start items-center pt-4">
            <Checkbox
              disabled={!edit}
              className="flex flex-row items-center justify-center py-0"
              checked={marketing}
              onCheckedChange={() => setMarketing(!marketing)}
            />
            <div className="pl-4">
              Wyrazam zgode na przetwarzanie moich danych osobowych w celach
              handlowych i marketingowych przez WeExpert sp. z o.o.
            </div>
          </div>

          {edit && (
            <div>
              {useFileCV && (
                <div>
                  <div className="w-full flex justify-between pt-4">
                    <div>
                      <input
                        accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        type="file"
                        className="block text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                                    file:rounded-sm file:border-0 file:text-sm file:font-semibold
                                    file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-4">
                <Label> I don't have cv as file </Label>
                <Switch
                  checked={!useFileCV}
                  disabled={!edit}
                  onCheckedChange={(e) => {
                    setUseFileCv(!e);
                    setCvLink("");
                  }}
                />
              </div>
              {!useFileCV && !dontHaveCv && (
                <div className="w-1/3 mt-4">
                  <InputWithAlert
                    disabled={!edit}
                    value={cvLink}
                    onChange={(e) => {
                      setCvLink(e.target.value);
                    }}
                    label={"CV/Portfolio link"}
                    platform={"CV/Portfolio link"}
                  />
                </div>
              )}

              {!useFileCV && (
                <div className="mt-4">
                  <Label>I don't have hosted resume</Label>
                  <Switch
                    disabled={!edit}
                    checked={dontHaveCv}
                    onCheckedChange={(e) => setDontHaveCV(e)}
                  />
                </div>
              )}
            </div>
          )}
          <div>
            {!edit ? (
              <div className="w-full flex justify-end">
                <Button
                  onClick={() => {
                    freelancerProfile && setEdit(true);
                  }}
                  className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none  ${freelancerProfile ? "text-blueLink" : "opacity-70"}`}
                >
                  {t("button_change")}
                </Button>
              </div>
            ) : (
              <div className="w-full flex justify-end">
                <Button
                  onClick={() => setEdit(false)}
                  className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none  ${freelancerProfile ? "text-blueLink" : "opacity-70"}`}
                >
                  {t("button_back")}
                </Button>
                <Button
                  onClick={() => {
                    if (!jobTitles || !tools || !experienceDate) {
                      return;
                    }
                    const links = [
                      {
                        platform: "LinkedIn",
                        link: linkedin,
                      },
                      {
                        platform: "Behance",
                        link: behance,
                      },
                      {
                        platform: "Telegram",
                        link: telegram,
                      },
                      {
                        platform: "WhatsApp",
                        link: whatsApp,
                      },
                    ];
                    return touchProfile(
                      jobTitles.map((e) => e.value),
                      tools.map((e) => e.value),
                      experienceDate,
                      +hourlyRate,
                      monthlyRate.value,
                      languagesData.map((e) => ({
                        code: e.language.value,
                        proficiency: e.proficiency.value,
                      })),
                      file,
                      links.filter((e) => e.link),
                      cvLink,
                      dontHaveCv,
                      interestedInProject,
                      maxHours,
                      marketing,
                    )
                      .then((res) => {
                        setEdit(false);
                      })
                      .catch((e) => {
                        if (e.response?.status === 413) {
                          toast({
                            title: t("error_large_file_title"),
                            description: t("error_large_file_description"),
                            variant: "destructive"
                          });
                          return
                        }
                        toast({
                          title: t("alerts:toast_error_counterparty_title"),
                          description: t("alerts:toast_error_counterparty_description"),
                          variant: "destructive"
                        });
                      });
                  }}
                >
                  {t("button_save")}
                </Button>
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}
