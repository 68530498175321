import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog"
import { Button } from "@/components/ui/button"
import { bulkRestorePassword } from "@/service/weexpertService";
import { toast } from "../ui/use-toast";

export default function ClientRestorePasswordPopup({ clientIds }) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const sendPasswordRestore = (clientIds) => {
    bulkRestorePassword(clientIds)
      .then(() => {
        toast({
          title: t('alerts:succes')
        })
      })
      .catch(() => {
        toast({
          title: t('alerts:error'),
          variant: 'destructive'
        })
      })
  }

  return (
    <BaseDialog
      dialogTitle={t("password_reset_button")}
      buttonText={t("password_reset_button")}
      open={open}
      setOpen={setOpen}
    >
      <div className="w-[400px] pt-4">
        {t("password_reset_message")}: {clientIds.length}
      </div>
      <div className="w-full flex flex-row justify-end mt-4">
        <Button variant={'outline'}  onClick={() => {
          setOpen(false)
        }}>
          {t('button_back')}
        </Button>
        <Button className={'ml-4'} onClick={() => {
          sendPasswordRestore(clientIds)
          setOpen(false)
        }}>
          {t('button_save')}
        </Button>
      </div>
    </BaseDialog>
  );
}
