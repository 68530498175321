import React, { useRef, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useTranslation } from "react-i18next";
import AppBarForLogin from "@/components/app-bar-for-main-login-pages/AppBarForLogin";
import { getUser, touchArtifactSatge } from "../../service/weexpertService";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";

export const ProfileStepThreePage = ({ next, back }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const { data: userData, isLoading: loading } = useQuery({
    queryKey: ["userMeProfile"],
    queryFn: getUser,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { profile } = data.data;
      if (!profile) {
        return;
      }
      if (profile.cvLink && profile.cvLink.startsWith("s3") === false) {
        setCvLink(profile.cvLink);
      }
      if (profile.links) {
        for (const profileLink of profile.links) {
          if (profileLink.platform === "LinkedIn") {
            setLinkedin(profileLink.link);
          }

          if (profileLink.platform === "Behance") {
            setBehance(profileLink.link);
          }

          if (profileLink.platform === "Telegram") {
            setTelegram(profileLink.link);
          }

          if (profileLink.platform === "WhatsApp") {
            setWhatsApp(profileLink.link);
          }
        }
      }
    },
  });

  const [useFileCV, setUseFileCv] = useState(true);
  const [dontHaveCv, setDontHaveCV] = useState(false);
  const [cvLink, setCvLink] = useState("");
  const [file, setFile] = useState();
  const [linkedin, setLinkedin] = useState("");
  const [behance, setBehance] = useState("");
  const [telegram, setTelegram] = useState("");
  const [whatsApp, setWhatsApp] = useState("");

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={"container"}>
      <AppBarForLogin isLogined={true} userData={userData.data} />
      <div className="pt-24 pb-28">
        <div className="text-3xl font-semibold w-96 pb-6">
          Step 3/4: Your resume and accounts
        </div>
        <div className="pb-6 text-base">
          Please, upload your CV and share social media, so that we know your
          history of work and staying up to date
        </div>

        <div className="w-1/3 mb-4 sm:w-[100%]">
          {useFileCV && (
            <div>
              <div className="w-full flex justify-between pt-4">
                <div>
                  <input
                    ref={fileInputRef}
                    accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    type="file"
                    className="block text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                           file:rounded-sm file:border-0 file:text-sm file:font-semibold
                           file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            <Label> I don't have CV as file </Label>
            <Switch
              checked={!useFileCV}
              onCheckedChange={(e) => {
                setUseFileCv(!e);
              }}
            />
          </div>
          {!useFileCV && !dontHaveCv && (
            <div className="w-1/3 mt-4">
              <InputWithAlert
                value={cvLink}
                onChange={(e) => {
                  setCvLink(e.target.value);
                }}
                label={"CV/Portfolio link"}
                platform={"CV/Portfolio link"}
              />
            </div>
          )}

          {!useFileCV && (
            <div className="mt-4">
              <Label>I don't have hosted resume</Label>
              <Switch
                checked={dontHaveCv}
                onCheckedChange={(e) => setDontHaveCV(e)}
              />
            </div>
          )}
          <div className="relative mt-4 w-2/4">
            <InputWithAlert
              value={linkedin}
              onChange={(e) => {
                setLinkedin(e.target.value);
              }}
              label={"LinkedIn"}
              placeholder={"LinkedIn"}
            />
          </div>
          <div className="relative mt-4 w-2/4">
            <InputWithAlert
              value={behance}
              onChange={(e) => {
                setBehance(e.target.value);
              }}
              label={"Behance"}
              placeholder={"Behance"}
            />
          </div>
          <div className="relative mt-4 w-2/4">
            <InputWithAlert
              value={telegram}
              onChange={(e) => {
                setTelegram(e.target.value);
              }}
              label={"Telegram"}
              placeholder={"Telegram"}
            />
          </div>
          <div className="relative mt-4 w-2/4">
            <InputWithAlert
              value={whatsApp}
              onChange={(e) => {
                setWhatsApp(e.target.value);
              }}
              label={"WhatsApp"}
              placeholder={"WhatsApp"}
            />
          </div>
        </div>
        <Progress value={75} />
        <div className="flex flex-row justify-between pt-6">
          <Button
            className="w-36"
            variant="outline"
            onClick={() => {
              if (back) {
                back();
              }
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              const links = [
                {
                  platform: "LinkedIn",
                  link: linkedin,
                },
                {
                  platform: "Behance",
                  link: behance,
                },
                {
                  platform: "Telegram",
                  link: telegram,
                },
                {
                  platform: "WhatsApp",
                  link: whatsApp,
                },
              ];
              touchArtifactSatge(
                file,
                links.filter((e) => e.link),
                cvLink,
                dontHaveCv,
              )
                .then((e) => {
                  next();
                })
                .catch((e) => {
                  if (e.response?.status === 413) {
                    toast({
                      title: t("error_large_file_title"),
                      description: t("error_large_file_description"),
                      variant: "destructive"
                    });
                    return
                  }
                  toast({
                    title: t("alerts:toast_error_counterparty_title"),
                    description: t("alerts:toast_error_counterparty_description"),
                    variant: "destructive"
                  });
                });
            }}
            className="w-36"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
