import React from "react";
import { useParams } from "react-router-dom";
import { getCVForClient, getNewOneFreelancer } from "@/service/weexpertService";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { Link } from "react-router-dom";
import { formatDistanceStrict, parseISO } from "date-fns";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { MainInformation } from "@/components/account-components/main.information";
import { LanguageProficiency } from "@/components/candidates/language_proficiency.component";
import { SkillsProficiency } from "@/components/candidates/skills_proficiency.component";
import { Loader } from "lucide-react";

function CandidateView({ userData, isLoading }) {
  const profile = userData?.profile;
  const { t } = useTranslation();
  const [jobTitle, setJobTitle] = useState(profile.jobTitles.join(", "));
  const [experience, setExperience] = useState("");
  const [links, setLinks] = useState(profile.links || []);

  const linksContent = (
    <div>
      {links.map((link, index) => (
        <div key={index} className="my-2">
          <span className=" text-xs">{link.platform}: </span>
          <Link
            to={link.link}
            className="text-blue-500 underline"
            target="_blank"
          >
            {link.link}
          </Link>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (profile && profile.experienceDate) {
      const experienceDate = parseISO(profile.experienceDate);
      const now = new Date();
      const experienceText = formatDistanceStrict(experienceDate, now, {
        addSuffix: false,
      });
      setExperience(experienceText);
    }
  }, [profile]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex justify-between pt-[10px] ">
        <div className="w-full pl-8">
          <div className="w-full pt-10">
            <InputWithAlert
              label={t("survey:label_job_title")}
              value={jobTitle}
              placeholder={t("survey:label_job_title")}
              disabled={true}
              inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
            />
            <InputWithAlert
              label={t("survey:label_experience")}
              value={experience}
              placeholder={t("survey:label_experience")}
              disabled={true}
              inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
            />
            <div className="text-sm font-medium py-4">
              {t("survey:label_programming_languages")}
            </div>
            <div className="pb-4">
            <SkillsProficiency knowleges={profile.knowleges} />
            </div>
            <InputWithAlert
              label={t("survey:label_rate")}
              value={profile.hourlyRate}
              placeholder={t("survey:label_rate")}
              disabled={true}
              inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
            />
            <InputWithAlert
              label={t("survey:monthly_rate")}
              value={`${profile.monthlyRate} USD`}
              placeholder={t("survey:monthly_rate")}
              disabled={true}
              inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
            />
            <div className="mt-2">
              <span className="text-[14px] font-medium	">
                {t("survey:interested_in_projects")}
                <br />
              </span>
              <Switch
                className="mt-2"
                checked={profile.interestedInProjects}
                disabled
              />
            </div>
            <div className="mt-2 text-[14px] font-medium">
              <span>
                {t("survey:marketing")} <br />
              </span>
              <Switch className="mt-2" checked={profile.marketing} disabled />
            </div>
            <div className="pt-4">
              <div className="text-sm font-medium">
                {t("survey:placeholder_languages")}
              </div>
              <LanguageProficiency languages={profile.languages} />
            </div>
            <div className="pb-">
              <div className="text-sm font-medium">{`${t("common:links")} :`}</div>
              {linksContent}
            </div>
            <div className="mt-2 flex flex-col ">
              <div className="flex items-center mb-8">
                <span>CV</span>
                <Switch
                  className="mt-2 mb-2"
                  checked={profile.cvLink}
                  disabled
                />
              </div>
              {profile.cvLink && (
                <div className="w-full flex justify-end pb-10">
                  <Button
                    onClick={() => {
                      if (profile.cvLink) {
                        getCVForClient(profile.client.id).then((res) => {
                          if (res.status === 200) {
                            window.open(res.data.url);
                          }
                        });
                      }
                    }}
                  >
                    {t("common:download")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default function CandidatePage(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["freelacer", id],
    queryFn: () => getNewOneFreelancer(id),
  });
  const profile = data?.data;
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <div className="pt-10">error</div>;
  }

  return (
    <div className="w-full pt-10">
      <div className="w-full flex items-center justify-between">
        <div className=" text-4xl px-8">
          {`${profile && profile.firstName} ${profile && profile.lastName}`}
        </div>
        <div>
          <Link to={"/candidates"}>
            <Button className="mr-10 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
              назад
            </Button>
          </Link>
        </div>
      </div>
      <Tabs
        defaultValue="profile"
        className="w-full md:w-12/12 lg:w-12/12 xl:w-8/12 pt-10 px-8"
      >
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="account">
            {t("users:main_information")}
          </TabsTrigger>
          <TabsTrigger value="profile">
            {t("users:freelancer_profile")}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <MainInformation userData={data.data} />
        </TabsContent>
        <TabsContent value="profile">
          <CandidateView isLoading={isLoading} userData={data.data} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
