import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQueryState } from "../invoices.page";
import { useState, useEffect } from "react";
import { getCandidates, getCandidatesExport } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DataTablePagination } from "@/components/ui/table-pagination";
import { formatDistanceStrict, parseISO } from "date-fns";
import { MONTHLY_RATE } from "@/profile.constants";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";

export function CandidatesPage(props) {
  const [searchQuery, setSearchQuery] = useState(null);
  const [sharedFilter, setSharedFilter] = useQueryState("f");
  const [filters, setFilters] = useState(
    JSON.parse(sharedFilter ?? "[]").f ?? [],
  );
  const [unprocessedFilter, setUnprocessedFilter] = useState(
    JSON.parse(sharedFilter ?? "{}").uf ?? {},
  );
  const [realData, setRealData] = useState("");
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [rowsPerPage, setRowsPerPage] = useState(pagination.pageSize);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    getCandidates(
      rowsPerPage,
      page * rowsPerPage,
      searchQuery,
      Object.values(filters),
    ).then((res) => {
      setRealData(res.data.clients);
      setTotal(res.data.total);
    });
  }, [rowsPerPage, page, filters, searchQuery]);

  const columns = [
    {
      accessorKey: "client",
      header: t("common:client"),
      cell: ({ row }) => {
        return (
          <Link className="capitalize" to={`/candidate/${row.original.id}`}>
            {`${row.original.firstName} ${row.original.lastName}`}
          </Link>
        );
      },
      key: uuidv4(),
    },
    {
      accessorKey: "Email",
      header: "Email",
      cell: ({ row }) => (
        <Link to={`/candidate/${row.original.id}`}>{row.original.email}</Link>
      ),
      key: uuidv4(),
    },
    {
      accessorKey: "currentJobTitle",
      header: t("common:job_title"),
      cell: ({ row }) => (
        <Link to={`/candidate/${row.original.id}`}>
          {row.original.profile.jobTitles.join(", ")}
        </Link>
      ),
      key: uuidv4(),
    },
    {
      accessorKey: "currentExperience",
      header: t("common:experience"),
      cell: ({ row }) => {
        const experienceDate = parseISO(row.original.profile.experienceDate);
        const now = new Date();
        const experienceText = formatDistanceStrict(experienceDate, now, {
          roundingMethod: "round",
          addSuffix: false,
        });
        return (
          <Link className="capitalize" to={`/candidate/${row.original.id}`}>
            {experienceText}
          </Link>
        );
      },
      key: uuidv4(),
    },
    {
      accessorKey: "skills",
      header: t("common:skills_tools"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/candidate/${row.original.id}`}>
          {row.original.profile.knowleges.join(", ")}
        </Link>
      ),
      key: uuidv4(),
    },
    {
      accessorKey: "hourly_rate",
      header: t("common:hourly_rate"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/candidate/${row.original.id}`}>
          {row.original.profile.hourlyRate} USD
        </Link>
      ),
      key: uuidv4(),
    },
    {
      accessorKey: "monthly_rate",
      header: t("common:monthly_rate"),
      cell: ({ row }) => {
        let rate = row.original.profile.monthlyRate;
        const returnedRates = MONTHLY_RATE.filter(
          (e) => e.value == row.original.profile.monthlyRate,
        );
        if (returnedRates.length != 0) {
          rate = returnedRates[0].label;
        }
        return (
          <Link className="capitalize" to={`/candidate/${row.original.id}`}>
            {rate}
          </Link>
        );
      },
      key: uuidv4(),
    },
    {
      accessorKey: "interestedInProject",
      header: t("common:projects"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/candidate/${row.original.id}`}>
          <Switch
            checked={row.original.profile.interestedInProjects}
            disabled
          />
        </Link>
      ),
      key: uuidv4(),
    },
    {
      accessorKey: "availability",
      header: t("common:availability"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/candidate/${row.original.id}`}>
          {row.original.profile.availability ?? 0}
        </Link>
      ),
      key: uuidv4(),
    },
    {
      accessorKey: "interestedInProject",
      header: t("common:marketing"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/candidate/${row.original.id}`}>
          <Switch checked={row.original.profile.marketing} disabled />
        </Link>
      ),
      key: uuidv4(),
    },
  ];
  useEffect(() => {
    setPage(pagination.pageIndex);
    setRowsPerPage(pagination.pageSize);
  }, [pagination]);

  const data = realData;
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: total,
    state: {
      pagination,
    },
    initialState: {
      pagination,
    },
  });
  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">{t("candidates")}</div>
            <div className="flex w-5/6">
              <Input
                placeholder={"Search"}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mr-2 ml-8 bg-white"
              />
            </div>
            <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={() => {
                getCandidatesExport()
              }}
            >
                Excel
            </Button>
          </div>
        </div>
        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border">
            {realData && (
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead className="pt-3 pb-3" key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center m-[0px]"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  );
}
