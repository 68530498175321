export function SkillsProficiency({ knowleges }) {
  const colorClasses = [
    "bg-pink-50 text-pink-600",
    "bg-blue-50 text-blue-600",
    "bg-green-50 text-green-600",
    "bg-yellow-50 text-yellow-600",
    "bg-purple-50 text-purple-600",
    "bg-indigo-50 text-indigo-600",
    "bg-teal-50 text-teal-600",
    "bg-gray-50 text-gray-600",
    "bg-cyan-50 text-cyan-600",
    "bg-amber-50 text-amber-600",
  ];

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2">
        {knowleges.map((skill, index) => (
          <span
            key={index}
            className={`inline-block rounded-full px-3 py-1 text-xs font-medium ${colorClasses[index % colorClasses.length]}`}
          >
            {skill}
          </span>
        ))}
      </div>
    </div>
  );
}