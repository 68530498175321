import {
  platformsOptions,
  sanitazeInput,
  SUPPORTED_CURRENCIES,
  validateOnlyLatin,
} from "@/constants";
import { InputWithAlert } from "../ui/input-with-alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button } from "../ui/button";
import { BaseDialog } from "./dialog";
import {
  createPlatformExlanation,
  createPlatformExlanationReportBased,
  editPlatformExplanation,
} from "@/service/weexpertService";
import { useQueryClient } from "react-query";
import { EditIcon } from "lucide-react";
import { toast } from "../ui/use-toast";

export const BankPlatformExplanationForm = ({
  onSaved,
  platformExplanation,
  reportBased = false,
}) => {
  const { t } = useTranslation();
  const {
    platformType = undefined,
    counterparty: initialCounterparty = undefined,
    invoice = undefined,
    invoiceSum = undefined,
    invoiceCurrency: initialCurrency = undefined,
    costInvoice = undefined,
    costInvoiceSum = undefined,
    costInvoiceCurrency: initialCostCurrency = undefined,
    bankAccount: initialBankAccount = undefined,
  } = platformExplanation ?? {};
  const [platform, setPlatform] = useState(platformType);
  const [counterparty, setCounterparty] = useState(initialCounterparty);

  const [invoiceId, setInvoiceId] = useState(invoice);
  const [invoiceCurrency, setInvoiceCurrency] = useState(initialCurrency);
  const [invoiceAmount, setInvoiceAmount] = useState(invoiceSum);

  const [bankAccount, setBankAccount] = useState(initialBankAccount);
  const [costInvoiceId, setCostInvoiceId] = useState(costInvoice);
  const [costInvoiceCurrency, setCostInvoiceCurrency] =
    useState(initialCostCurrency);
  const [costinvoiceAmount, setCostInvoiceAmount] = useState(costInvoiceSum);
  const [inputCheked, setInputChecked] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [costAmountError, setCostAmountError] = useState(false);

  const MAX_VALUE = 10 ** 8;

  const handleInvoiceAmountChange = (e) => {
    const sanitizedValue = sanitazeInput(e.target.value);
    const numericValue = Number(sanitizedValue);
    setInvoiceAmount(sanitizedValue);

    if (sanitizedValue && numericValue > MAX_VALUE) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  };

  const handleCostInvoiceAmountChange = (e) => {
    const sanitizedValue = sanitazeInput(e.target.value);
    const numericValue = Number(sanitizedValue);
    setCostInvoiceAmount(sanitizedValue);

    if (sanitizedValue && numericValue > MAX_VALUE) {
      setCostAmountError(true);
    } else {
      setCostAmountError(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="mb-2">
          <SelectWithAlert
            label={t("platfom_form_input_platform")}
            value={platform}
            options={platformsOptions}
            onChangeValue={(e) => setPlatform(e)}
            error={inputCheked && !platform}
            errorMessage={t("empty_data_invalid")}
            inputClassName={"h-8 text-xs p-[4px] bg-white mt-1"}
          />
        </div>
        <div>
          <InputWithAlert
            label={t("counterparty")}
            value={counterparty}
            error={inputCheked && !counterparty}
            errorMessage={t("empty_data_invalid")}
            onChange={(e) => {
              if (e.target.value === "") {
                setCounterparty(e.target.value);
              }
              if (validateOnlyLatin(e.target.value)) {
                setCounterparty(e.target.value);
              }
            }}
            inputClassName={"h-8"}
          />
        </div>

        {reportBased && (
          <div>
            <InputWithAlert
              label={t("platfom_form_input_bank_account")}
              value={bankAccount}
              error={inputCheked && !bankAccount && reportBased}
              errorMessage={t("empty_data_invalid")}
              onChange={(e) => {
                if (e.target.value === "") {
                  setBankAccount(e.target.value);
                }
                if (validateOnlyLatin(e.target.value)) {
                  setBankAccount(e.target.value);
                }
              }}
              inputClassName={"h-8"}
            />
          </div>
        )}

        <div className="pt-2">
          <InputWithAlert
            label={t("platfom_form_input_invoice_id")}
            value={invoiceId}
            error={inputCheked && !invoiceId && !costInvoiceId}
            errorMessage={t("empty_data_invalid")}
            onChange={(e) => {
              if (e.target.value === "") {
                setInvoiceId(e.target.value);
              }
              if (validateOnlyLatin(e.target.value)) {
                setInvoiceId(e.target.value);
              }
            }}
            inputClassName={"h-8"}
          />
        </div>
        <div className="pt-2">
          <SelectWithAlert
            label={t("invoices:currency")}
            options={SUPPORTED_CURRENCIES}
            onChangeValue={setInvoiceCurrency}
            value={invoiceCurrency}
            error={inputCheked && invoiceId && !invoiceCurrency}
            errorMessage={t("empty_data_invalid")}
            inputClassName={"h-8 bg-white"}
          />
        </div>
        <div className="pt-2">
          <InputWithAlert
            label={t("platfom_form_input_invoice_amount")}
            value={invoiceAmount}
            onChange={handleInvoiceAmountChange}
            inputClassName={"h-8"}
            errorMessage={
              (inputCheked &&
                invoiceId &&
                !invoiceAmount &&
                t("empty_data_invalid")) ||
              (costAmountError && t("error_message_for_number_length_input"))
            }
            error={inputCheked && invoiceId && (amountError || !invoiceAmount)}
          />
        </div>

        <div className="pt-2">
          <InputWithAlert
            label={t("platfom_form_input_cost_invoice_id")}
            value={costInvoiceId}
            error={inputCheked && !invoiceId && !costInvoiceId}
            errorMessage={t("empty_data_invalid")}
            onChange={(e) => {
              if (e.target.value === "") {
                setCostInvoiceId(e.target.value);
              }
              if (validateOnlyLatin(e.target.value)) {
                setCostInvoiceId(e.target.value);
              }
            }}
            inputClassName={"h-8"}
          />
        </div>
        <div className="pt-2">
          <SelectWithAlert
            label={t("platfom_form_input_cost_currency")}
            options={SUPPORTED_CURRENCIES}
            onChangeValue={setCostInvoiceCurrency}
            value={costInvoiceCurrency}
            errorMessage={t("empty_data_invalid")}
            error={inputCheked && costInvoiceId && !costInvoiceCurrency}
            inputClassName={"h-8 bg-white"}
          />
        </div>
        <div className="pt-2">
          <InputWithAlert
            label={t("platfom_form_input_cost_invoice_amount")}
            value={costinvoiceAmount}
            onChange={handleCostInvoiceAmountChange}
            inputClassName={"h-8"}
            error={
              inputCheked &&
              costInvoiceId &&
              (costAmountError || !costinvoiceAmount)
            }
            errorMessage={
              (inputCheked &&
                costInvoiceId &&
                !costinvoiceAmount &&
                t("empty_data_invalid")) ||
              (costAmountError && t("error_message_for_number_length_input"))
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col items-end">
        <Button
          className="mt-4"
          onClick={() => {
            setInputChecked(true);
            const invoicePresent = !!invoiceId || !!costInvoiceId;
            if (!invoicePresent) {
              return;
            }
            if (!counterparty) {
              return;
            }
            if (invoiceId && (!invoiceCurrency || !invoiceAmount)) {
              return;
            }
            if (costInvoiceId && (!costInvoiceCurrency || !costinvoiceAmount)) {
              return;
            }
            if (reportBased && !bankAccount) {
              return;
            }
            return onSaved(
              counterparty,
              platform,
              invoiceId
                ? {
                    invoiceId: invoiceId,
                    invoiceAmount: invoiceAmount,
                    invoiceCurrency: invoiceCurrency,
                  }
                : null,
              costInvoiceId
                ? {
                    invoiceId: costInvoiceId,
                    invoiceAmount: costinvoiceAmount,
                    invoiceCurrency: costInvoiceCurrency,
                  }
                : null,
              bankAccount ? bankAccount : null,
            );
          }}
        >
          {t("button_add")}
        </Button>
      </div>
    </>
  );
};

export const BankPlatformExplanationEditPopup = ({
  platformExplanation,
  bankReportId = null,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const onSaved = (
    counterparty,
    platformType,
    invoice,
    costInvoice,
    bankAccount,
  ) => {
    return editPlatformExplanation(
      platformExplanation.id,
      counterparty,
      platformType,
      invoice,
      costInvoice,
      bankAccount,
    ).then((response) => {
      if (response.status === 200) {
        toast({
          title: t("alerts:toast_succes_invoice_edit_description"),
          description: t("alerts:toast_succes_invoice_edit_description"),
        });
      }
      queryClient.invalidateQueries({ queryKey: ["transaction"] });
      setOpen(false);
    });
  };
  return (
    <BaseDialog
      dialogTitle={""}
      className={
        "bg-background shadow-sm hover:bg-accent hover:text-accent-foreground text-black border-black"
      }
      ommitStyles={true}
      buttonText={<EditIcon />}
      open={open}
      setOpen={setOpen}
    >
      <div className="w-[600px] pr-1 pl-1 ">
        <BankPlatformExplanationForm
          onSaved={onSaved}
          platformExplanation={platformExplanation}
          reportBased={bankReportId}
        />
      </div>
    </BaseDialog>
  );
};

export const BankPlatformExplanationCreatePopup = ({
  transactionId,
  bankReportId = null,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const onSaved = (
    counterparty,
    platformType,
    invoice,
    costInvoice,
    bankAccount,
  ) => {
    if (transactionId) {
      return createPlatformExlanation(
        transactionId,
        counterparty,
        platformType,
        invoice,
        costInvoice,
      ).then((response) => {
        queryClient.invalidateQueries({ queryKey: ["transaction"] });
        setOpen(false);
        if (response.status === 200) {
          toast({
            title: t("alerts:toast_succes_invoice_create_description"),
            description: t("alerts:toast_succes_invoice_create_description"),
          });
        }
      });
    } else if (bankReportId) {
      return createPlatformExlanationReportBased(
        bankReportId,
        counterparty,
        platformType,
        invoice,
        costInvoice,
        bankAccount,
      ).then((response) => {
        if (response.status === 200) {
          toast({
            title: t("alerts:toast_succes_invoice_create_description"),
            description: t("alerts:toast_succes_invoice_create_description"),
          });
        }
        queryClient.invalidateQueries({ queryKey: ["transaction"] });
        setOpen(false);
      });
    }
  };

  return (
    <BaseDialog
      dialogTitle={""}
      buttonText={t("platform_pop_up_button")}
      open={open}
      setOpen={setOpen}
      ommitStyles={true}
      className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
    >
      <div className="w-[600px] pr-1 pl-1 ">
        <BankPlatformExplanationForm
          onSaved={onSaved}
          reportBased={!transactionId}
        />
      </div>
    </BaseDialog>
  );
};
