import * as React from 'react';
import { InfoIcon, Loader } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { formatDateString } from '@/styles';

export function MainInformation(props) {

    const { userData } = props;
    const { t } = useTranslation();

    if (!userData) {
        return <Loader />
    }

    return (
        <div className='w-full'>
            <div className='flex '>
                <div className='w-6/12'>
                    <div className='pb-2'>{t("name")}</div>
                    <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && userData.firstName}
                    </div>
                    <div className='pb-2'>{t("phone")}</div>
                    <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && userData.phone}
                    </div>
                    <div className='pb-2'>{t("origin")}</div>
                    <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && userData.citizenship}
                    </div>
                    {(userData?.pesel) && (
                        <>
                            <div className='pb-2'>Pesel</div>
                            <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                                {userData && userData.pesel}
                            </div>
                        </>
                    )}
                    {userData && userData?.bankAccounts?.filter(e => e.accountType !== 'CLIENT').map(e => {
                        return (
                            <>
                                <div className='pb-2'>{t("clients:bank_account_inqubator")}</div>
                                <div className='mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                                    {e.account}
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className='w-6/12 '>
                    <div className='pb-2 pl-2'>{t("last_name")}</div>
                    <div className='ml-2 p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && userData.lastName}
                    </div>
                    <div className='pb-2 pl-2'>Email</div>
                    <div className='ml-2 p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && userData.email}
                    </div>
                    <div className='pb-2 pl-2'>{t("clients:create_client_placeholder_date_of_birth")}</div>
                    <div className='ml-2 p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && formatDateString(userData.dateOfBirth)}
                    </div>
                    <div className='pb-2 pl-2'>{t("clients:create_client_placeholder_passport")}</div>
                    <div className='ml-2 p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                        {userData && userData.passport}
                    </div>
                    {userData && userData?.bankAccounts?.filter(e => e.accountType === 'CLIENT').map(e => {
                        return (
                            <>
                                <div className='pb-2 pl-2'>{t("clients:bank_account_client")}</div>
                                <div className='ml-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60'>
                                    {e.account}
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    );

}
