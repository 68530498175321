import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ContractorsForm, { CONTRACTORS_FORM_ACTION_EDIT } from "../components/contractors/ContractorsForm";
import { ACTIVE_STATUS, COMPANY_TYPE,COUNTRIES_LIST, COUNTRY_TYPE, transformFromIdValueToValueLabel, validateNoneCyrillic } from "../constants";
import { assignClientToContractors, changeContractor, getContractorById, unassignClientFromContractors } from "../service/weexpertService";
import { Button } from "@/components/ui/button";
import { toast } from '@/components/ui/use-toast';
import RelatedClientsTable from "@/components/clients/RelatedClientsTable";
import AssignClientTablePopup from '@/components/popups/user.assign.clients.popup';

const CounterpartyEditPage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [refreshData, setRefreshData] = useState(false);
  const [contractor, setContractor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputChecked, setInputChecked] = useState(false);
  const [counterpartyType, setCounterpartyType] = useState();
  const [counterpartyOrigin, setCounterpartyOrigin] = useState();
  const [counterpartyName, setConterpartyName] = useState('');
  const [counterpartyAdress, setCounterpartyAdress] = useState('');
  const [counterpartyCity, setCounterpartyCity] = useState('');
  const [counterpartyEmail, setCounterpartyEmail] = useState('');
  const [counterpartyPostIndex, setCounterpartyPostIndex] = useState();
  const [counterpartyCountry, setCounterpartyCountry] = useState();
  const [counterpartyNip, setCounterpartyNip] = useState();
  const [counterpartyStatus, setCounterpartyStatus] = useState();
  const handleRefreshData = () => {
    setRefreshData(prevRefreshData => !prevRefreshData);
  };

  useEffect(() => {
    if (id) {
      getContractorById(id)
        .then(res => {
          if (res.data === null) {
            navigate('/404')
          }
          setConterpartyName(res.data?.name ?? '')
          setContractor(res.data);
          setCounterpartyType(COMPANY_TYPE(t)
            .filter(i => i.id === res.data.counterpartyType)
            .map(transformFromIdValueToValueLabel)[0]
          );
          setCounterpartyNip(res.data.NIP);
          setCounterpartyOrigin(COUNTRY_TYPE(t)
            .filter(i => i.id === res.data.origin)
            .map(transformFromIdValueToValueLabel)[0]
          );
          setCounterpartyAdress(res.data.address);
          setCounterpartyPostIndex(res.data.postIndex)
          setCounterpartyEmail(res.data.email)
          setCounterpartyStatus(ACTIVE_STATUS(t)
            .filter(i => i.id === res.data.active)
            .map(transformFromIdValueToValueLabel)[0]);
          const default_country = res.data.country ?
            (COUNTRIES_LIST.filter(e => e.id == res.data.country).length > 0 ?
              COUNTRIES_LIST.filter(e => e.id == res.data.country).map(transformFromIdValueToValueLabel).map(e => {
                return {
                  content: {
                    value: e.value,
                    label: "Polska"
                  },
                  ...e,
                  value: e.label
                }
              })[0] :
              {
                content: {
                  value: "pl",
                  label: "Polska"
                },
                value: "Polska",
                label: "Polska"
              }) :
            {
              content: {
                value: "pl",
                label: "Polska"
              },
              value: "Polska",
              label: "Polska"

            };
          setCounterpartyCountry(default_country);
          setCounterpartyCity(res.data.city);
        }).then(() => {
          setLoading(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            navigate('/404')
          } else {
            console.error("Error:", error);
          }
        });
    }

  }, [id, refreshData]);

  const onSave = () => {
    setInputChecked(true)
    if (
      (!counterpartyName || !validateNoneCyrillic(counterpartyName)) ||
      (!counterpartyAdress && !validateNoneCyrillic(counterpartyAdress)) ||
      (!counterpartyCity && !validateNoneCyrillic(counterpartyCity)) ||
      (!counterpartyPostIndex && validateNoneCyrillic(counterpartyPostIndex)) ||
      !counterpartyType ||
      !counterpartyOrigin
    ) {
      return
    }

    changeContractor(
      contractor.id,
      counterpartyType.value,
      counterpartyName,
      counterpartyAdress,
      counterpartyPostIndex,
      counterpartyCity,
      counterpartyEmail,
      counterpartyCountry.content.value,
      counterpartyNip,
      counterpartyOrigin.value,
      counterpartyStatus.value
    )
      .then((response) => {
        toast({
          title: t("alerts:toast_succes_counterparty_edit_title"),
          description: t("alerts:toast_succes_counterparty_edit_description"),
        })
        navigate('/admin/contractors')
      })
      .catch((error) => {
        toast({
          title: t("alerts:toast_error_counterparty_title"),
          description: t("alerts:toast_error_counterparty_description"),
          variant: "destructive"
        })
      });
  }

  return (
    <Box>
      {loading ? (
        <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center ">
              <div className="text-4xl ">
                {t("counterparty")}
              </div>
              <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                onClick={() => { onSave(); }}>
                {t("button_save")}
              </Button>
            </div>
          </div>
          <div className='w-full py-8'>
            <ContractorsForm
              action={CONTRACTORS_FORM_ACTION_EDIT}
              inputChecked={inputChecked}
              companyType={counterpartyType}
              setCompanyType={setCounterpartyType}
              NIP={counterpartyNip}
              setNIP={setCounterpartyNip}
              companyName={counterpartyName}
              setCompanyName={setConterpartyName}
              companyOrigin={counterpartyOrigin}
              setCompanyOrigin={setCounterpartyOrigin}
              companyCountry={counterpartyCountry}
              setCompanyCountry={setCounterpartyCountry}
              postIndex={counterpartyPostIndex}
              setPostIndex={setCounterpartyPostIndex}
              mail={counterpartyEmail}
              setMail={setCounterpartyEmail}
              city={counterpartyCity}
              setCity={setCounterpartyCity}
              address={counterpartyAdress}
              setAddress={setCounterpartyAdress}
              currentStatus={counterpartyStatus}
              setCurrentStatus={setCounterpartyStatus}
            />
          </div>
          <div className='w-full flex justify-between items-center pb-8'>
            <div className="w-full text-xl font-medium ">
              {t("clients")}
            </div>
            <AssignClientTablePopup
              header={t('assign_client_to_counterparty')}
              person={contractor}
              handleRefreshData={handleRefreshData}
              onSelect={(clientId) => {
                assignClientToContractors(contractor.id, clientId).then((res) => {
                  handleRefreshData()
                })
              }}
              onUnselect={(clientId) => {
                unassignClientFromContractors(contractor.id, clientId).then(() => {
                  handleRefreshData()
                })
              }}
              initialSelection={contractor.clients.map(e => e.id)}
            />
          </div>
          <div className='w-full pb-16'>
            <RelatedClientsTable entity={contractor} counterparty={true} handleRefreshData={handleRefreshData} />
          </div>
        </div>
      )}
    </Box>
  )
}
export default CounterpartyEditPage
